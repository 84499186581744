import React, { useState } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
const cx = cn.bind(styles);
import { FaStar, FaRegStar } from 'react-icons/fa';

type RatingDisProps = {
  ratingScore: number;
  size: number;
};

type RatingProps = {
  rating: number;
  setRating: (score: number) => void;
  size: number;
};

const Rating = ({ rating, setRating, size }: RatingProps) => {
  const [hover, setHover] = useState(0);
  return (
    <div className={cx('star-rating')}>
      {[...Array(5)].map((_, index) => {
        index += 1;
        const status = index <= (hover || rating);
        return status ? (
          <FaStar
            key={index}
            size={size}
            fill="#FBBF24"
            onClick={() => setRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
            className="pointer"
          />
        ) : (
          <FaRegStar
            key={index}
            size={size}
            fill="#FBBF24"
            onClick={() => setRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
            className="pointer"
          />
        );
      })}
    </div>
  );
};

export const RatingDisplay = ({ ratingScore, size }: RatingDisProps) => {
  const floorScore = Math.floor(ratingScore);
  const fractionDigit = ratingScore - floorScore;
  const fractionWidth = fractionDigit * size;

  return (
    <div className={cx('star-display')}>
      {[...Array(5)].map((_, index) => {
        index += 1;
        const width =
          index <= floorScore ? size : fractionDigit ? (index === floorScore + 1 ? fractionWidth : 0) : fractionWidth;

        return (
          <div className={cx('star-stack')} key={index}>
            <FaRegStar className="pointer" fill="#FBBF24" size={size} />
            <div className={cx('star-stack-active')} style={{ width }}>
              <FaStar className="pointer" fill="#FBBF24" size={size} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const RatingLine: React.FC<{ className: string }> = ({ className }) => {
  const stars = [
    { label: '5 star', value: 80 },
    { label: '4 star', value: 50 },
    { label: '3 star', value: 60 },
    { label: '2 star', value: 12 },
    { label: '1 star', value: 12 },
  ];
  return (
    <div className={cx('star-line', 'flex-col miw-200', `${className ? className : ''}`)}>
      {stars.map((item, index) => {
        const width = (item.value / 100) * 100;

        return (
          <div className="flex-align w-full mb-10" key={index}>
            <span className="w-40 fw-400 fz-14 lh-15 text-info">{item.label}</span>
            <div className="h-13 ml-8 mr-8 bd-rds-37 flex-1 bg-secondary">
              <div className="h-full bd-rds-37 bg-yellow" style={{ width: `${width}%` }}></div>
            </div>
            <span className="w-28 fw-400 fz-14 lh-15 text-info">{Math.round(width * 100) / 100}%</span>
          </div>
        );
      })}
    </div>
  );
};

export default Rating;
