import React, { createContext, useContext, useState } from 'react';

interface LoadingProps {
  pageLoading: boolean;
  setPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}

const defaultValues: LoadingProps = {
  loading: false,
  setLoading: () => {},
  pageLoading: false,
  setPageLoading: () => {},
  status: '',
  setStatus: () => {}
};

const LoadingContext = createContext(defaultValues);

export const LoadingProvider: React.FC<{
  children?: React.ReactElement | React.ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [status, setStatus] = useState('');

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        pageLoading,
        setPageLoading,
        status,
        setStatus
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
