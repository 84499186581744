import styles from './index.module.scss';
import { PageNotFound } from 'assets/icons';
import cn from 'classnames/bind';
import React from 'react';

const cx = cn.bind(styles);

const titleCode = {
  0: 'Sorry.. there was an error',
  404: '404 - Page not found',
  500: '500 - internal server error',
};

interface ErrorPageProps {
  statusCode: number;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ statusCode }) => {
  return (
    <div className={cx('page-not-found')}>
      <div className={cx('content')}>
        <PageNotFound alt="404-Page" />
        <h1
          className={cx('title')}
          dangerouslySetInnerHTML={{
            __html: titleCode[statusCode as keyof typeof titleCode],
          }}
        />
        <span className={cx('descript')}>
          There’s nothing to see here. Try reloading the page, use another keyword, or go back to your previous page
        </span>
      </div>
    </div>
  );
};
