import styles from './index.module.scss';
import {
  IconArrowLeft,
  IconArrowLeftActive,
  IconArrowRight,
  IconArrowRightActive,
  IconBack,
  IconBackNoActive,
  IconDown,
} from 'assets/icons';
import cn from 'classnames/bind';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';

const cx = cn.bind(styles);

interface PaginationProps {
  startPage: number;
  total: number;
  pageSize: number;
  position?: 'top' | 'bottom';
  className?: string;
  styles?: CSSProperties;
  onChange?: (e: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  total,
  pageSize,
  startPage,
  position,
  className,
  styles,
  onChange,
}) => {
  const getNumberOfPage = (total: number, pageSize: number) => {
    return total % pageSize !== 0 ? Math.floor(total / pageSize) + 1 : total / pageSize;
  };
  const [currentPage, setCurrentPage] = useState(startPage);
  const numOfPage = getNumberOfPage(total, pageSize);
  const allPage = Array.from({ length: numOfPage }, (_, i) => i + 1);
  const refDiv = useRef<HTMLDivElement>(null);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const currentRef = useRef(startPage);

  const handleChosePage = (item: number) => {
    setCurrentPage(item);
    setIsShowPopup((pre) => !pre);
  };

  useOnClickOutside(refDiv, () => setIsShowPopup(false));

  useEffect(() => {
    if (onChange) {
      onChange(currentPage);
    }
  }, [currentPage]);

  return (
    <div
      className={cx(
        'pagination',
        `pos-rel flex-align flex-between mt-28 w-220 bg-white pa-8 pl-10 pr-10 bd-rds-40 ${className ? className : ''}`,
      )}
      style={styles}
    >
      {React.createElement(currentRef.current === currentPage ? IconBackNoActive : IconBack, {
        onClick: () => setCurrentPage(currentRef.current),
        alt: 'icon back',
        className: 'pointer',
      })}
      <div className="border-line-x border-btn h-16" />
      <div className="flex-align pointer" ref={refDiv}>
        <span
          onClick={() => {
            currentRef.current = currentPage;
            setIsShowPopup((pre) => !pre);
          }}
        >
          {currentPage} of {numOfPage} <IconDown className="ml-8" alt="Icon Down" />
        </span>
        {isShowPopup && (
          <div
            className={cx(
              'content',
              `pos-abs pa-6 flex-col bg-white bd-rds-10 ${
                position === 'bottom' ? 'top-45 left-50' : 'bottom-45 left-50'
              }`,
            )}
          >
            {allPage.length &&
              allPage.map((item) => (
                <span
                  onClick={() => handleChosePage(item)}
                  key={item}
                  className="pl-10 w-72 h-24 pa-6 hover-secondary bd-rds-5 flex-align fw-400 fz-14 lh-15"
                >
                  {item}
                </span>
              ))}
          </div>
        )}
      </div>

      <div className="border-line-x border-btn h-16" />
      <div className="flex-align">
        {React.createElement(currentPage === 1 ? IconArrowLeft : IconArrowLeftActive, {
          onClick: () => {
            currentRef.current = currentPage;
            setCurrentPage((pre: number) => (pre > 1 ? pre - 1 : pre));
          },
          className: 'pointer',
          alt: 'icon arrow left',
        })}
        {React.createElement(currentPage === numOfPage ? IconArrowRight : IconArrowRightActive, {
          onClick: () => {
            currentRef.current = currentPage;
            setCurrentPage((pre: number) => (pre < numOfPage ? pre + 1 : pre));
          },
          className: 'ml-15 pointer',
          alt: 'icon arrow light',
        })}
      </div>
    </div>
  );
};
