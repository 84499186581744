import styles from './index.module.scss';
import { IconEmptyImage } from 'assets/icons';
import cn from 'classnames/bind';
import { Tooltip } from 'components';
import { ReactComponentLike } from 'prop-types';
import React, { createElement } from 'react';

const cx = cn.bind(styles);

type CardMarketProps = {
  id: string;
  thumbnail: string;
  name: string;
  desc?: string | null;
  tag?: string | null;
  rateDollar: number;
  packageMaxPrice?: number;
  packageMinPrice: number;
  totalPackage: number;
  totalSold: number;
  createdBy?: string | null;
  categories?: { name: string; order: number; id: string }[] | null;
  imgTag?: ReactComponentLike;
  hasTagDiscovery?: string;
  subContent?: {
    addon?: React.ReactNode;
    nameAddon?: React.ReactNode;
    footerRight?: React.ReactNode;
    footerLeft?: React.ReactNode;
  };
  action?: {
    onClick?: (id: string) => void;
  };
};

const TooltipWrap: React.FC<{ content: string; children: React.ReactElement | string | number }> = ({
  children,
  content,
}) => (
  <Tooltip position="top" content={content}>
    <span className="ellipsis maw-80 w-fit-content inline-block">{children}</span>
  </Tooltip>
);

export const CardMarket = ({
  id,
  thumbnail,
  name,
  tag,
  imgTag,
  hasTagDiscovery,
  rateDollar,
  packageMaxPrice,
  packageMinPrice = 0,
  totalPackage,
  totalSold,
  subContent,
  action,
}: CardMarketProps) => {
  return (
    <div
      className={cx('card', 'pos-rel flex-col bg-white pa-16 bd-rds-12 pointer')}
      onClick={() => action?.onClick?.(id)}
    >
      {tag && (
        <span className={cx('mark-tag', 'pos-abs left-0 top-20 fw-600 fz-12 lh-15 text-white z-index-1')}>{tag}</span>
      )}
      {thumbnail ? (
        createElement(imgTag ?? 'img', {
          className: 'object-fit-cv w-full mah-120 bd-rds-6',
          src: thumbnail,
          alt: thumbnail,
          width: 290,
          height: 120,
        })
      ) : (
        <div className="object-fit-cv w-full mah-120 bd-rds-6 overflow-hidden">
          <IconEmptyImage preserveAspectRatio="xMidYMin" className="relative-pos-center-x" />
        </div>
      )}
      <div className="mt-12 flex-between h-48">
        <span className="fw-600 fz-16 lh-15 ellipsis-2">
          {name}
          {subContent?.nameAddon ?? null}
        </span>
        {subContent?.addon && <span className="miw-44">{subContent.addon}</span>}
      </div>
      {hasTagDiscovery && (
        <div className="mt-4">
          <span className="fw-500 fz-12 lh-15 text-blue">{hasTagDiscovery}</span>
        </div>
      )}
      <div className="mt-12 mb-15 flex-col">
        <span className="fw-400 fz-12 lh-15 Archivo text-info">Available</span>
        <span className="Archivo fw-700 fz-16 lh-15 flex-between">
          <div className="flex-align">
            <TooltipWrap content={packageMinPrice.toString()}>{packageMinPrice}</TooltipWrap>
            {packageMaxPrice && (
              <>
                <span style={{ alignSelf: 'baseline' }}> ~ </span>
                <TooltipWrap content={packageMaxPrice.toString()}>{packageMaxPrice}</TooltipWrap>
              </>
            )}
          </div>
          <span className={cx('orai', 'h-16 Archivo ml-8 pl-4 pr-4 text-white bd-rds-4 fw-700 fz-12 lh-15')}>ORAI</span>
        </span>
        <span className="Archivo flex fw-400 lh-15 fz-14 text-gray">
          <span className={cx('dola', 'Archivo mr-3 fz-18')}>$</span>{' '}
          <TooltipWrap content={Number(packageMinPrice * rateDollar).toString()}>
            {Number(packageMinPrice * rateDollar)}
          </TooltipWrap>
          {packageMaxPrice && (
            <>
              ~{' '}
              <TooltipWrap content={Number(packageMaxPrice * rateDollar).toString()}>
                {Number(packageMaxPrice * rateDollar)}
              </TooltipWrap>
            </>
          )}
        </span>
      </div>
      <div className="grid-5-5 bg-secondary bd-rds-4 pa-8">
        {[
          { title: 'Total packages', content: totalPackage },
          { title: 'Total sold', content: totalSold },
        ].map((item) => (
          <div className="flex-col" key={item.title}>
            <span className="Archivo fw-400 fz-12 lh-15 text-gray">{item.title}</span>
            <span className="Archivo fw-400 fz-14 lh-15">{item.content || '-'}</span>
          </div>
        ))}
      </div>
      {(subContent?.footerRight || subContent?.footerLeft) && (
        <div className="mt-12 flex-align flex-between">
          <div>{subContent?.footerLeft}</div>
          <div>{subContent?.footerRight}</div>
        </div>
      )}
    </div>
  );
};
