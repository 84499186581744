import React, { useState, useEffect } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';

const cx = cn.bind(styles);

export interface TabList {
  key: string;
  icon?: React.ReactNode | React.ReactElement | React.ReactElement[] | React.ReactNode[];
  iconActive?: React.ReactNode | React.ReactElement | React.ReactElement[] | React.ReactNode[];
  tab: string;
  content: any;
  onClick?: () => void;
}

const Tabs: React.FC<{
  list: TabList[];
  tabActive?: string;
}> = ({ list, tabActive }) => {
  const [active, setActive] = useState<string | string[]>(list[0].key);

  useEffect(() => {
    tabActive && setActive(tabActive);
  }, [tabActive]);

  return (
    <div className={cx('tabs', 'pa-12 pl-24 pr-24 ')}>
      <div className={cx('tabs-head', 'flex-align')}>
        {list.map((item) => (
          <div
            key={item.key}
            onClick={() => (item?.onClick ? item.onClick() : setActive(item.key))}
            className={cx('tab-item')}
          >
            {item.icon && item.iconActive && (active === item.key ? item.iconActive : item.icon)}
            <span className="fw-700 fz-14 lh-14 text-info pt-10 pb-14 pl-24 pr-24 pointer">{item.tab}</span>
            <hr
              className={cx(active === item.key ? 'active' : 'flex-none')}
              style={{ marginBottom: '-10px', marginTop: '5px' }}
            />
          </div>
        ))}
      </div>
      <hr className="border-line-y mb-32" />
      <div className={cx('tabs-content')}>{list.find((item) => item.key === active)?.content}</div>
    </div>
  );
};

export default Tabs;
