import React from 'react';

const MultiProviders: React.FC<{
  props: any;
}> = ({ props }) => {
  let content = props.children || null;

  /* Error/Validation */
  if (!props.providers) {
    throw 'MultiProvider: Missing providers prop';
  }

  if (!props.children) {
    throw 'MultiProvider: Missing children';
  }

  // Turn object into an array
  // const numberOfProviders = props.providers.size;
  const numberOfProviders = props.providers.length;

  if (!numberOfProviders) {
    // Providers prop is empty, r
    return content;
  }

  []
    .concat(props.providers ?? [])
    .reverse()
    .forEach((provider) => {
      content = React.cloneElement(provider, {}, content);
    });

  return content;
};

export default MultiProviders;
