import styles from './index.module.scss';
import cn from 'classnames/bind';
import { DinoLoading } from 'components';
import React, { CSSProperties } from 'react';

const cx = cn.bind(styles);

type LoadingPageProps = {
  show?: boolean;
  animationData?: any;
  style?: CSSProperties;
};

const LoadingPage: React.FC<LoadingPageProps> = ({ show, style, animationData }) => {
  return (
    <div className={cx('loading-page-wrapper', show ? 'show' : '')}>
      <div style={style} className={cx('loading-page', show ? 'show' : '')}>
        <DinoLoading size={24} loading={true} animationData={animationData} />
      </div>
    </div>
  );
};

export default LoadingPage;
