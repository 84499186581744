import { UserProvider } from '../providers/UserProvider';
import GlobalStyles from './GlobalStyles';
import Content from './content';
import { LayoutProps } from './model';
import React from 'react';

export const CommonLayout: React.FC<LayoutProps> = (props) => {
  return (
    <UserProvider
      baseApiURL={props.baseApiURL}
      baseAuthURL={props.baseAuthURL}
      lcdApiURL={props.lcdApiURL}
      notificationConfig={props.notificationSetting}
      typeUserRole={props.typeUserRole}
    >
      <GlobalStyles
        primaryColor={props?.primaryColor}
        secondaryColor={props?.secondaryColor}
        tertiaryColor={props?.tertiaryColor}
      />
      <Content
        lcdApiURL={props.lcdApiURL}
        baseApiURL={props.baseApiURL}
        ssoServer={props.ssoServer}
        locationOrigin={props.locationOrigin}
        logo={props.logo}
        menuList={props.menuList}
        baseAuthURL={props.baseAuthURL}
        breadcrumbs={props.breadcrumbs}
        hasSidebar={props.hasSidebar}
        onLoginSuccess={props.onLoginSuccess}
        goToMyProfile={props.goToMyProfile}
        onRouterPush={props.onRouterPush}
        goToServiceSaved={props.goToServiceSaved}
        pathName={props.pathName}
        switchProjectList={props.switchProjectList}
        primaryColor={props?.primaryColor}
        backgroundInfoUser={props?.backgroundInfoUser}
      >
        {props.children}
      </Content>
    </UserProvider>
  );
};
