import styles from './index.module.scss';
import cn from 'classnames/bind';
import React, { CSSProperties } from 'react';

const cx = cn.bind(styles);

interface SpinProps {
  style?: CSSProperties;
  size?: 'small' | 'default' | 'large';
  color?: string;
}

export const SpinCommon = ({ style, size, color }: SpinProps) => {
  return (
    <div className={cx('loader')} style={style}>
      <div className={cx('box', size)}>
        <span style={{ color }}></span>
        <span style={{ color }}></span>
        <span style={{ color }}></span>
        <span style={{ color }}></span>
      </div>
    </div>
  );
};
