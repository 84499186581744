import useAuthenticate from '../../hooks/useAuthenticate';
import { useInfoUser } from '../../providers/UserProvider';
import styles from './index.module.scss';
import cn from 'classnames/bind';
import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';

const cx = cn.bind(styles);

declare global {
  interface Window {
    wasm: any;
    lockLoginSSO: any;
  }
}

interface LoginWidgetProps {
  type?: string;
  text?: string;
  style?: CSSProperties;
  buttonStyle?: CSSProperties;
  baseAuthURL: string;
  ssoServer: string;
  reacted?: boolean;
  mainColor?: string;
  locationOrigin: string;
  onLoginSuccess?: (user: any) => void;
}

const LoginWidget: React.FC<LoginWidgetProps> = ({
  baseAuthURL,
  ssoServer,
  type = 'onPage',
  text,
  style = {},
  buttonStyle = {},
  reacted,
  mainColor,
  locationOrigin,
  onLoginSuccess,
}) => {
  const { loginWithSSO } = useAuthenticate(baseAuthURL);
  const { user } = useInfoUser();
  const windowRef = useRef<any>(null);

  const initialUserChecking = useCallback(() => {
    if (user?.id && reacted && onLoginSuccess) {
      onLoginSuccess(user);
    }
  }, [user, onLoginSuccess]);

  useEffect(() => {
    if ((user?.id, onLoginSuccess, reacted)) {
      initialUserChecking();
    }
  }, [initialUserChecking, user, onLoginSuccess, reacted]);

  useEffect(() => {
    // const queryData = url.parse(window.location.href, true).query;
    const handler = async (event: any) => {
      // if (event.origin !== ssoServer) return;

      const receivedData = event.data;

      if (receivedData === 'logout') {
        window.wasm?.removeChildKey();
        return;
      }

      if (!receivedData || typeof receivedData !== 'string' || receivedData === 'ready' || receivedData === 'logout') {
        return;
      }

      if (windowRef?.current) {
        windowRef.current.close();
      }
      if (window.lockLoginSSO) {
        return;
      }
      window.lockLoginSSO = true;
      try {
        const parsedData = JSON.parse(receivedData);
        await loginWithSSO(parsedData?.token);
      } catch (ex) {
        console.log(ex);
      }
      window.lockLoginSSO = false;
    };
    // Listen to event from sub-window
    const authentication = async () => {
      window.addEventListener('message', handler, false);

      return;
    };

    authentication();
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  return (
    <div className={cx('widget-container', { small: type === 'inline' })}>
      <iframe
        loading="lazy"
        src={`${ssoServer}/login/embeded/${type}?serviceURL=${locationOrigin}&text=${text}&style=${encodeURIComponent(
          JSON.stringify(style),
        )}&buttonStyle=${encodeURIComponent(JSON.stringify(buttonStyle))}&mainColor=${mainColor}`}
      />
    </div>
  );
};

export default LoginWidget;
