import { BgProfile, Copied, Logout, Profile } from '../../assets';
import { AvatarPlaceholder } from '../../components/AvatarPlaceholder';
import LoginWidget from '../../components/LoginWidget';
import { Tooltip } from '../../components/Tooltip';
import useAuthenticate from '../../hooks/useAuthenticate';
import { centerTextEllipsis, copyClipboard } from '../../utils';
import styles from './index.module.scss';
import cn from 'classnames/bind';
import { DropdownCustom } from 'components';
import { useInfoUser } from 'hooks';
import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

const cx = cn.bind(styles);

const InfoUser: React.FC<{
  baseAuthURL: string;
  onLoginSuccess?: (user: any) => void;
  goToMyProfile: () => void;
  locationOrigin: string;
  ssoServer: string;
  primaryColor?: string;
  backgroundInfoUser?: React.ReactElement | React.ReactNode | any;
}> = ({ baseAuthURL, onLoginSuccess, locationOrigin, ssoServer, goToMyProfile, primaryColor, backgroundInfoUser }) => {
  const { isLoggedIn, logout } = useAuthenticate(baseAuthURL);
  const { user, balanceUser } = useInfoUser();

  const triggerElement = useMemo(() => {
    return (
      <div className={cx('user-info')}>
        <AvatarPlaceholder address={user?.walletAddress} src={user?.avatar} alt="user-avatar" />
        <span>{user?.username || 'Unnamed'}</span>
        <div className={cx('icon-down')}></div>
      </div>
    );
  }, [user]);

  const contentDropdown = useMemo(() => {
    return (
      <div className={cx('function')}>
        <div className={cx('box-function')}>
          {backgroundInfoUser ?? <BgProfile />}
          <div className={cx('box-content')}>
            <div className={cx('blance')}>
              <div className={cx('money')}>
                <p>BALANCE</p>
                {balanceUser} ORAI
              </div>
              <a href="https://scan.orai.io/" target="_blank" className={cx('plus')}>
                <AiOutlinePlus />
              </a>
            </div>
            <Tooltip content="Copied!" type="click">
              <div className={cx('wallet')} onClick={() => copyClipboard(user?.walletAddress)}>
                <span>{centerTextEllipsis(user?.walletAddress, 8)}</span>
                <Copied alt="copy" />
              </div>
            </Tooltip>
            <div
              className={cx('fc-menu')}
              onClick={() => {
                goToMyProfile();
              }}
            >
              <Profile alt="profile" />
              <span>My profile</span>
            </div>
          </div>
        </div>
        <div className={cx('footer')}>
          <div
            className={cx('logout')}
            onClick={() => {
              logout();
            }}
          >
            <Logout alt="logout" /> <span>Log out</span>
          </div>
        </div>
      </div>
    );
  }, [balanceUser, user, logout]);

  const initialUserChecking = useCallback(() => {
    if (user && isLoggedIn && onLoginSuccess) {
      onLoginSuccess(user);
    }
  }, [user, onLoginSuccess, isLoggedIn]);

  useEffect(() => {
    if (user?.id && isLoggedIn && onLoginSuccess) {
      initialUserChecking();
    }
  }, [initialUserChecking, user, isLoggedIn]);

  return isLoggedIn && user ? (
    <div className={cx('user')}>
      <DropdownCustom
        styles={{ top: 'calc(100% + 14px)' }}
        content={contentDropdown}
        position="bottom-right"
        triggerElement={triggerElement}
      />
    </div>
  ) : (
    <Suspense>
      <LoginWidget
        baseAuthURL={baseAuthURL}
        ssoServer={ssoServer}
        style={{ overflow: 'hidden' }}
        buttonStyle={{ background: primaryColor ?? '#dc3571', boxShadow: 'none' }}
        type="inline"
        text="Connect Wallet"
        mainColor={primaryColor ?? '#dc3571'}
        locationOrigin={locationOrigin}
      />
    </Suspense>
  );
};

export default InfoUser;
