import styles from './index.module.scss';
import cn from 'classnames/bind';
import React, { CSSProperties } from 'react';

const cx = cn.bind(styles);

interface LoadingProps {
  loading: boolean;
  children?: any;
  style?: CSSProperties;
  size?: 'small' | 'default' | 'large';
  color?: string;
  replaceLoading?: any;
}

const Loading = ({ loading = false, children, style, size, color, replaceLoading }: LoadingProps) => {
  if (loading) {
    return (
      <div className={cx('loader')} style={style}>
        {replaceLoading ? (
          replaceLoading
        ) : (
          <div className={cx('box', size)}>
            <span style={{ color }}></span>
            <span style={{ color }}></span>
            <span style={{ color }}></span>
            <span style={{ color }}></span>
          </div>
        )}
      </div>
    );
  }

  return children;
};

export default Loading;
