import { ReactElement, ReactNode } from 'react';

export enum KeyTab {
  paid = 'paid',
  free = 'free',
}

export const ASYNC_TAB = [
  {
    key: KeyTab.free,
    text: 'Free call',
  },
  {
    key: KeyTab.paid,
    text: 'Paid call',
  },
];

export enum RequestStatus {
  RUNNING = 'running',
  FAILED = 'failed',
  TIMEDOUT = 'timedout',
  RESOLVED = 'resolved',
}

export type HandleOnclickCallingDetail = (requestId: string, serviceId: string, isFree: boolean) => void;

export type AsyncRequestItemProps = {
  serviceName: string;
  endpointPath: string;
  endpointMethod: string;
  status: RequestStatus;
  statusCode: number | string | null;
  id: string;
  msTime: number;
  reactJson: (value: object) => ReactElement | ReactNode;
  baseApiURL: string;
  isFree: boolean;
  requestId: string;
  onRouterPush: (url: string) => void;
  getListCallAsync: ({ isFree }: { isFree: boolean }) => void;
  handleOnClickCallingDetail?: HandleOnclickCallingDetail;
};

export type AsyncRequestItemResponse = {
  elapse_time: number;
  endpoint_id: string;
  endpoint_method: string;
  endpoint_path: string;
  id: string;
  is_free: boolean;
  package_offering_id: string;
  service_name: string;
  start_at: string;
  status: RequestStatus;
  status_code: number | string | null;
};

export type AsyncRequestDetail = {
  body: string;
  code: number;
  endpoint_id: string;
  endpoint_method: string;
  endpoint_name: string;
  endpoint_path: string;
  id: string;
  is_free: boolean;
  package_offering_id: string;
  running_time: number;
  service_id: string;
  service_name: string;
  start_at: string;
};
