import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import withPrefixUrl from 'utils/withPrefixUrl';
import Loading from 'components/Loading';

const MyAPIs = React.lazy(() => import('pages/MyAPIs'));
const CreateAPIs = React.lazy(() => import('pages/CreateAPIs'));
const DetailAPI = React.lazy(() => import('pages/DetailAPI'));
const TestcaseLogs = React.lazy(() => import('pages/TestcaseLogs'));
const Login = React.lazy(() => import('pages/Login'));
const Protected = React.lazy(() => import('components/Protected'));
const Claim = React.lazy(() => import('pages/Claim'));
const BoughtAPI = React.lazy(() => import('pages/BoughtAPIs'));
const User = React.lazy(() => import('pages/User'));
const DetailBoughtAPI = React.lazy(() => import('pages/DetailBoughtAPI'));
const RequestLogs = React.lazy(() => import('pages/RequestLogs'));

const DinoRoutes: React.FC = () => {
  const { pathname } = useLocation();

  if (pathname === '/')
    return <Navigate to={withPrefixUrl('/apis/my-apis')} replace />;

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 300px)'
          }}
        >
          <Loading />
        </div>
      }
    >
      <Routes>
        <Route
          path={withPrefixUrl('/apis')}
          element={
            <Protected>
              <MyAPIs />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/:serviceId')}
          element={
            <Protected>
              <DetailAPI />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/:serviceId/testcase-logs')}
          element={
            <Protected>
              <TestcaseLogs />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/my-apis')}
          element={
            <Protected>
              <MyAPIs />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/my-apis/create')}
          element={
            <Protected>
              <CreateAPIs />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/my-apis/:serviceId')}
          element={
            <Protected>
              <DetailAPI />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/my-apis/:serviceId/testcase-logs')}
          element={
            <Protected>
              <TestcaseLogs />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/claim-earnings')}
          element={
            <Protected>
              <Claim />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/bought-apis')}
          element={
            <Protected>
              <BoughtAPI />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/bought-apis/:serviceId')}
          element={
            <Protected>
              <DetailBoughtAPI />
            </Protected>
          }
        />
        <Route
          path={withPrefixUrl('/apis/bought-apis/:serviceId/request-logs')}
          element={
            <Protected>
              <RequestLogs />
            </Protected>
          }
        />
        <Route path={'/user'} element={<User />} />
        <Route path={withPrefixUrl('/login/*')} element={<Login />} />
      </Routes>
    </Suspense>
  );
};

export default DinoRoutes;
