import { Header } from './header';
import { HeaderMobile } from './header-mobile';
import styles from './index.module.scss';
import { LayoutProps } from './model';
import { Sidebar } from './sidebar';
import cn from 'classnames/bind';
import { useWindowDimensions } from 'hooks';
import React, { useState } from 'react';

const cx = cn.bind(styles);

const Content: React.FC<Omit<LayoutProps, 'notificationSetting'>> = ({
  children,
  logo,
  menuList,
  breadcrumbs,
  baseAuthURL,
  baseApiURL,
  hasSidebar = true,
  locationOrigin,
  ssoServer,
  onLoginSuccess,
  goToMyProfile,
  onRouterPush,
  pathName,
  goToServiceSaved,
  switchProjectList,
  primaryColor,
  backgroundInfoUser,
}) => {
  const [open, setOpen] = useState(true);
  const { width } = useWindowDimensions();

  return (
    <div className={cx('common-layout')}>
      {width > 500 && (
        <div className={cx('content-wrapper')}>
          <Header
            locationOrigin={locationOrigin}
            goToMyProfile={goToMyProfile}
            onLoginSuccess={onLoginSuccess}
            goToServiceSaved={goToServiceSaved}
            onRouterPush={onRouterPush}
            baseAuthURL={baseAuthURL}
            baseApiURL={baseApiURL}
            breadcrumbs={breadcrumbs}
            logo={logo}
            ssoServer={ssoServer}
            switchProjectList={switchProjectList}
            primaryColor={primaryColor}
            backgroundInfoUser={backgroundInfoUser}
          />
          <div className={cx('main-container')}>
            {hasSidebar ? (
              <div className={cx('main')}>
                <Sidebar open={open} setOpen={setOpen} pathName={pathName} menuList={menuList} />
                <div className={cx('content', !open ? 'sidebar-close' : '')}>{children}</div>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      )}
      {width <= 500 && (
        <div className={cx('content-wrapper')}>
          <HeaderMobile
            locationOrigin={locationOrigin}
            goToMyProfile={goToMyProfile}
            onLoginSuccess={onLoginSuccess}
            goToServiceSaved={goToServiceSaved}
            baseAuthURL={baseAuthURL}
            logo={logo}
            ssoServer={ssoServer}
          />
          <div className={cx('main-container')}>{children}</div>
        </div>
      )}
    </div>
  );
};

export default Content;
