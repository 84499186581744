import React from 'react';

const GlobalStyles = React.memo(
  ({
    primaryColor,
    secondaryColor,
    tertiaryColor,
  }: {
    primaryColor?: string;
    secondaryColor?: string;
    tertiaryColor?: string;
  }) => {
    return (
      <style>
        {`:root{
        --common-primaryColor: ${primaryColor ?? '#ec5c90'};
        --common-secondaryColor: ${secondaryColor ?? '#fff3f6'};
        --common-tertiaryColor: ${tertiaryColor ?? '#ef729f'};
        }`}
      </style>
    );
  },
);

export default GlobalStyles;
