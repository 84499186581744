import styles from './index.module.scss';
import { Columns, RowSelection } from './types';
import cn from 'classnames/bind';
import { Button, SpinCommon } from 'components';
import React, { CSSProperties, memo, useCallback, useEffect, useState } from 'react';

const cx = cn.bind(styles);

export type HeaderTable = {
  title: string;
  primary?: {
    label: string;
    action: () => void;
    disable?: boolean;
    style?: CSSProperties;
  };
  secondaryList?: Array<{
    visible?: boolean;
    label: string;
    action: () => void;
    style?: CSSProperties;
  }>;
};

export type TableProps = {
  columns: Columns[];
  dataSource: any;
  rowSelection?: RowSelection | undefined | boolean;
  loading?: boolean;
  className?: any;
  type?: 'normal' | 'medium';
  isRefresh?: boolean;
  headerData?: HeaderTable;
};

const Table = ({
  headerData: d,
  columns,
  rowSelection,
  dataSource,
  loading,
  className,
  type = 'normal',
  isRefresh,
}: TableProps) => {
  const [dataSelected, setDataSelected]: any = useState([]);
  const [isAll, setIsAll] = useState(false);
  const onSelect = typeof rowSelection === 'object' && rowSelection?.onSelect;

  useEffect(() => {
    if (dataSelected.length === dataSource.length) {
      setIsAll(true);
    } else {
      setIsAll(false);
    }
  }, [dataSelected]);

  useEffect(() => {
    setDataSelected([]);
  }, [isRefresh]);

  useEffect(() => {
    setDataSelected([]);
  }, [dataSource]);

  const handleSelectChange = useCallback(
    (value: boolean, item: any) => {
      if (value) {
        setDataSelected([...dataSelected, item]);
        onSelect && onSelect([...dataSelected, item]);
      } else {
        setDataSelected(dataSelected.filter((dt: any) => dt.id !== item.id));
        onSelect && onSelect(dataSelected.filter((dt: any) => dt.id !== item.id));
      }
    },
    [dataSelected, onSelect],
  );

  const handleSelectAll = useCallback(
    (value: boolean) => {
      if (value) {
        setIsAll(true);
        setDataSelected(dataSource);
        onSelect && onSelect(dataSource);
      } else {
        setIsAll(false);
        setDataSelected([]);
        onSelect && onSelect([]);
      }
    },
    [setDataSelected, dataSource, onSelect],
  );

  const handleSelectOption = useCallback(
    (value: string | number) => {
      onSelect && onSelect([value]);
    },
    [onSelect],
  );

  return (
    <div className={cx('table-wrapper')}>
      {d && (
        <div className={cx('table-header')}>
          <span className={cx('title')}>{d?.title}</span>
          <div className={cx('actions')}>
            {d.primary && (
              <Button style={d.primary.style} type="default" size="large" onClick={d.primary.action}>
                {d.primary.label}
              </Button>
            )}
            {d.secondaryList?.map(
              (secondary, index) =>
                secondary.visible && (
                  <Button
                    key={index}
                    className={cx('secondary')}
                    type="outline"
                    size="large"
                    style={secondary.style}
                    onClick={secondary.action}
                  >
                    {secondary.label}
                  </Button>
                ),
            )}
          </div>
        </div>
      )}
      <div className={cx('table-loading')}></div>

      <div className={cx('table', loading ? 'loading' : '', className, type)}>
        {loading && (
          <div className={cx('table-loading')}>
            <SpinCommon size="small" />
          </div>
        )}
        <div className={cx('table-head')}>
          {columns.map((col) => (
            <div style={col?.style} key={col.key} className={cx('table-head-item', col?.align)}>
              {col?.title}
            </div>
          ))}
          {rowSelection && (
            <div
              className={cx(
                'table-head-item',
                'selection',
                typeof rowSelection === 'object' ? rowSelection?.align : '',
              )}
            >
              {typeof rowSelection === 'object' && rowSelection?.type !== 'radio' && (
                <div className={cx('custom-checkbox')}>
                  <input checked={isAll} onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                  <span className={cx('checkmark-head')}></span>
                </div>
              )}
            </div>
          )}
        </div>
        {dataSource.map((item: any, index: number) => (
          <div key={index} className={cx('table-body')}>
            {columns.map((col) => (
              <div style={col?.style} key={col.key} className={cx('table-body-item', col?.align)}>
                {col?.render ? col.render(item[col.key], item) : item[col.key]}
              </div>
            ))}
            {rowSelection && (
              <div className={cx('table-body-item', 'selection')}>
                {typeof rowSelection === 'object' && rowSelection?.type !== 'radio' ? (
                  <div className={cx('custom-checkbox')}>
                    <input
                      checked={dataSelected.map((dt: any) => dt.id).includes(item.id)}
                      onChange={(e) => handleSelectChange(e.target.checked, item)}
                      type="checkbox"
                    />
                    <span className={cx('checkmark')}></span>
                  </div>
                ) : (
                  <div className={cx('custom-radio')}>
                    <input onChange={() => handleSelectOption(item.id)} type="radio" name="radio-table" />
                    <span className={cx('checkmark')}></span>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Table);
