import { BreadcrumbProps } from '../model';
import InfoUser from './InfoUser';
import NotificationHeader from './Notification';
import SwitchProject, { SwitchProjectList } from './SwitchProject';
import styles from './index.module.scss';
import cn from 'classnames/bind';
import React from 'react';

const cx = cn.bind(styles);
interface HeaderProps {
  logo: React.ReactNode | React.ReactElement;
  breadcrumbs?: BreadcrumbProps[];
  baseAuthURL: string;
  baseApiURL: string;
  onLoginSuccess?: (user: any) => void;
  goToMyProfile: () => void;
  goToServiceSaved: () => void;
  onRouterPush: (val: string) => void;
  locationOrigin: string;
  ssoServer: string;
  switchProjectList: SwitchProjectList[];
  primaryColor?: string;
  backgroundInfoUser?: React.ReactElement | React.ReactNode | any;
}

export const Header: React.FC<HeaderProps> = ({
  logo,
  breadcrumbs,
  baseAuthURL,
  baseApiURL,
  onLoginSuccess,
  goToMyProfile,
  onRouterPush,
  locationOrigin,
  ssoServer,
  switchProjectList,
  primaryColor,
  backgroundInfoUser,
}) => {
  return (
    <div className={cx('header')}>
      <div className={cx('box')}>
        <div className={cx('left')}>
          <div className={cx('logo')}>{logo}</div>
          {breadcrumbs && (
            <div className={cx('breadcrumb')}>
              <ul>
                {breadcrumbs.map((item, index) => (
                  <li key={item.key}>
                    {index !== 0 && <span>/</span>}
                    <div onClick={item.onClick}>{item.title}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className={cx('right')}>
          {/* <div className={cx('saved')} onClick={goToServiceSaved}>
            <BookMark alt="saved" />
          </div> */}
          <SwitchProject switchProjectList={switchProjectList} />
          <NotificationHeader baseApiURL={baseApiURL} onRouterPush={onRouterPush} />
          <InfoUser
            ssoServer={ssoServer}
            locationOrigin={locationOrigin}
            goToMyProfile={goToMyProfile}
            baseAuthURL={baseAuthURL}
            onLoginSuccess={onLoginSuccess}
            primaryColor={primaryColor}
            backgroundInfoUser={backgroundInfoUser}
          />
        </div>
      </div>
    </div>
  );
};
