export type onNotificationCb = (payload: Record<string, any>) => Promise<any>;
export type NotificationSetting = Record<string, any>;

export default abstract class INotificationManager<T extends NotificationSetting> {
  private settings: T;
  constructor(settings: T) {
    this.settings = settings;
  }

  abstract addOnMessageHandler(callback: onNotificationCb): void;
  abstract changeOnMessageHandlerList(callbacks: onNotificationCb[]): void;

  public getSettings(): T {
    return this.settings;
  }
}
