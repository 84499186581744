import styles from './index.module.scss';
import { IconCategory, IconLink } from 'assets';
import cn from 'classnames/bind';
import { DropdownCustom } from 'components';
import React, { useMemo } from 'react';

const cx = cn.bind(styles);

export interface SwitchProjectList {
  key: string;
  name: string;
  logo: React.ReactElement | React.ReactNode;
  url: string;
}

const SwitchProject: React.FC<{
  switchProjectList: SwitchProjectList[];
}> = ({ switchProjectList }) => {
  const triggerElement = useMemo(() => {
    return (
      <div className={cx('box-icon')}>
        <IconCategory />
      </div>
    );
  }, []);

  const contentDropdown = useMemo(() => {
    return (
      <div className={cx('switch-content')}>
        <div className={cx('switch-title')}>Switch to</div>
        <div className={cx('project-list')}>
          {switchProjectList.map((item) => (
            <a key={item.key} href={item.url} className={cx('project-item')} title={item.name}>
              <div className={cx('item-logo')}>{item.logo}</div>
              <div className={cx('item-name')}>{item.name}</div>
            </a>
          ))}
        </div>
        <div className={cx('switch-footer')}>
          <a href="https://dinohub.io/" title="DINO Hub">
            dinohub.io <IconLink />
          </a>
        </div>
      </div>
    );
  }, []);

  return (
    <div className={cx('switch-project')}>
      <DropdownCustom styles={{ top: 'calc(100% + 13px)' }} triggerElement={triggerElement} content={contentDropdown} />
    </div>
  );
};

export default SwitchProject;
