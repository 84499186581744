import INotificationManager, {
  NotificationSetting,
  onNotificationCb,
} from 'core/adapter/notification/INotificationManager';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

export interface FirebaseConfig extends NotificationSetting {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export default class FirebaseNotification extends INotificationManager<FirebaseConfig> {
  private app: FirebaseApp | null = null;
  private callbacks: onNotificationCb[] = [];
  private messaging: any | null = null;
  private callbackCancel: () => void = () => null;

  static factory(firebaseConfig: FirebaseConfig): FirebaseNotification {
    return new FirebaseNotification(firebaseConfig);
  }

  constructor(firebaseConfig: FirebaseConfig) {
    super(firebaseConfig);
    if (typeof window !== 'undefined') {
      this.app = initializeApp({
        apiKey: firebaseConfig.apiKey,
        authDomain: firebaseConfig.authDomain,
        projectId: firebaseConfig.projectId,
        storageBucket: firebaseConfig.storageBucket,
        messagingSenderId: firebaseConfig.messagingSenderId,
        appId: firebaseConfig.appId,
        measurementId: firebaseConfig.messagingSenderId,
      });

      this.messaging = getMessaging(this.app);

      onMessage(this.messaging, (payload) => {
        this.entryPointCallback(payload);
      });
    }
  }

  public initFirebaseToken(): Promise<string | null> {
    return Promise.resolve(null);
    // if (!this.messaging) {
    //   return Promise.resolve(null);
    // }
    // return new Promise((resolve, reject) => {
    //   getToken(this.messaging, { vapidKey: this.getSettings().vapidKey })
    //     .then((firebaseToken) => {
    //       resolve(firebaseToken);
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });
  }

  private entryPointCallback(messagePayload: Record<string, any>) {
    return Promise.all(this.callbacks.map((callback) => callback(messagePayload)));
  }

  addOnMessageHandler(callback: onNotificationCb): void {
    this.callbacks.push(callback);
  }

  changeOnMessageHandlerList(callbacks: onNotificationCb[]): void {
    this.callbacks = callbacks;
  }

  public clear() {
    this.callbackCancel();
  }
}
