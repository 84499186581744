import React, { ReactElement, CSSProperties } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';

const cx = cn.bind(styles);

export const Tooltip: React.FC<{
  children?: ReactElement | string;
  content?: ReactElement | string;
  position?: 'top' | 'left' | 'right' | 'bottom';
  style?: CSSProperties;
  type?: 'click' | 'hover';
  width?: number;
}> = ({ children, content, position, style, type = 'hover', width }) => {
  const [active, setActive] = React.useState('');

  const handleClick = () => {
    if (type === 'hover') return;
    setActive('active');
    setTimeout(() => {
      setActive('');
    }, 2000);
  };

  if (!content) return <>{children}</>;

  return (
    <div className={cx('tooltip', type)} onClick={handleClick}>
      <div style={{ ...style, width }} className={cx('content', position || 'top', active)}>
        {content}
      </div>
      {children}
    </div>
  );
};
