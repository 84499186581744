import React, { useCallback } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import { ChildMenu } from '../model';

const cx = cn.bind(styles);

const SubMenu: React.FC<{
  child: ChildMenu;
  active: string;
  setActive: (value: string | ((prevState: string) => string)) => void;
}> = ({ child, active, setActive }) => {

  const handleClick = useCallback(() => {
    setActive(child.path);
    child.onClick();
  }, [child]);

  const isClassActive = useCallback(() => {
    if (active.includes(child.path)) return true;
    return false;
  }, [active, child.path]);

  return (
    <li className={cx(isClassActive() ? 'active' : '')} onClick={handleClick}>{child.title}</li>
  );
}

export default SubMenu;