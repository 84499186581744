import { isPlainObject } from 'lodash';
import CachedLocalStorage from 'utils/localStorage';

export const getTokenFromStorage = (): string | null => {
  const cachedLocalStoraged = new CachedLocalStorage();
  return cachedLocalStoraged.getWithExpiry('token');
};
export type METHOD = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type Request = {
  url: string;
  method?: METHOD;
  data?: any;
  params?: Record<string, any>;
  headers?: Record<string, any>;
  token?: string;
  baseURL?: string;
  contentType?: string;
  isAuthen?: boolean;
};

export type Response = {
  data: any;
  error: any;
};

// const instance = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE,
//   timeout: 10000,
//   headers: {'X-Custom-Header': 'foobar'}
// });

export interface ResponseX<T> {
  success: boolean;
  data?: T;
  error?: Error;
  code?: number;
}

export interface PaginationData<T> {
  total: number;
  offset: number;
  limit: number;
  items: T[];
}

class NodeAPI {
  public baseUrl: string;
  public tokenRetriever: () => string | null = getTokenFromStorage;

  constructor(baseUrl: string, tokenRetriever?: () => string) {
    this.baseUrl = baseUrl;
    this.tokenRetriever = tokenRetriever ?? this.tokenRetriever;
  }

  static factory(baseUrl: string, tokenRetriever?: () => string) {
    return new NodeAPI(baseUrl, tokenRetriever);
  }

  getResourceUrl(resource: string, searchParams?: Record<string, any>) {
    return (
      (this.baseUrl + '/' + resource).replace(/([^:])(\/\/+)/g, '$1/') +
      (searchParams ? '?' + new URLSearchParams(searchParams) : '')
    );
  }
  public async execute<ReturnType = any>(request: Request): Promise<ResponseX<ReturnType>> {
    const method = request.method || 'GET';
    request.headers = {
      ...request.headers,
      'Accept-Encoding': '*',
    };
    request.headers['Content-Type'] = request.contentType || 'application/json';

    if (request.isAuthen) {
      let accessToken = request.token;
      if (!accessToken) {
        const token = this.tokenRetriever();
        if (!token) throw new Error('Unauthorized!');
        accessToken = token;
      }

      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
    try {
      let parsedBody = request.data;
      const isFormData = request.data instanceof FormData ? true : false;
      if (isPlainObject(request.data) && !isFormData) {
        parsedBody = JSON.stringify(request.data);
      }
      if (isFormData) {
        delete request.headers['Content-Type'];
      }

      const res = await fetch(this.getResourceUrl(request.url, request.params), {
        method,
        headers: new Headers(request.headers),
        body: parsedBody,
      });
      if (res.ok) {
        const resJson = await res.json();
        const data = resJson?.data ?? resJson;
        return toSuccessResponse<ReturnType>(data || null, res.status);
      } else {
        return toFailedResponse<ReturnType>(undefined, res.status, await res.json());
      }
    } catch (err: any) {
      return toFailedResponse(err);
    }
  }
}

export function toSuccessResponse<T>(data: T, code: number): ResponseX<T> {
  return { success: true, data, code };
}
export function toFailedResponse<T>(error?: Error, code?: number, data?: T): ResponseX<T> {
  return { success: false, error, code, data };
}

export default NodeAPI;
