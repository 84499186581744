/**
 * This file was automatically generated by @cosmwasm/ts-codegen@0.27.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */
import {
  AllOwnershipOfferingsResponse,
  AllUsageOfferingsResponse,
  ContractInfoResponse,
  DatasetsByOwnerResponse,
  ExecuteMsg,
  InstantiateMsg,
  NumAllOwnershipOfferingsResponse,
  NumAllUsageOfferingsResponse,
  NumDatasetsByOwnerResponse,
  NumOwnershipBuyingRequestsOfResponse,
  NumUsageBuyingsOfResponse,
  OfferingsOfResponse,
  OwnershipBuyingRequestsOfResponse,
  QueryMsg,
  UsageBuyingOfResponse,
  UsageBuyingsOfResponse,
} from './DataMarketImplement.types';
import {
  Addr,
  Dataset,
  DatasetReponse,
  Datasource,
  NormalDataset,
  OwnershipBuyRequest,
  OwnershipBuyRequestStatus,
  OwnershipOffering,
  Testcase,
  TestcaseAttrs,
  Uint128,
  UsageOffering,
  UsageOfferingSold,
} from './types';
import { Coin, StdFee } from '@cosmjs/amino';
import { CosmWasmClient, ExecuteResult, SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';

export interface DataMarketImplementReadOnlyInterface {
  contractAddress: string;
  contractInfo: () => Promise<ContractInfoResponse>;
  offeringsOf: ({ tokenId }: { tokenId: string }) => Promise<OfferingsOfResponse>;
  dataset: ({ tokenId }: { tokenId: string }) => Promise<DatasetReponse>;
  datasetsByOwner: ({
    limit,
    owner,
    startAfter,
  }: {
    limit?: number;
    owner: string;
    startAfter?: string;
  }) => Promise<DatasetsByOwnerResponse>;
  numDatasetsByOwner: ({ owner }: { owner: string }) => Promise<NumDatasetsByOwnerResponse>;
  allUsageOfferings: ({
    limit,
    startAfter,
  }: {
    limit?: number;
    startAfter?: string;
  }) => Promise<AllUsageOfferingsResponse>;
  numAllUsageOfferings: () => Promise<NumAllUsageOfferingsResponse>;
  allOwnershipOfferings: ({
    limit,
    startAfter,
  }: {
    limit?: number;
    startAfter?: string;
  }) => Promise<AllOwnershipOfferingsResponse>;
  numAllOwnershipOfferings: () => Promise<NumAllOwnershipOfferingsResponse>;
  usageBuyingsOf: ({
    limit,
    startAfter,
    tokenId,
  }: {
    limit?: number;
    startAfter?: string;
    tokenId: string;
  }) => Promise<UsageBuyingsOfResponse>;
  numUsageBuyingsOf: ({ tokenId }: { tokenId: string }) => Promise<NumUsageBuyingsOfResponse>;
  ownershipBuyingRequestsOf: ({
    limit,
    startAfter,
    tokenId,
  }: {
    limit?: number;
    startAfter?: string;
    tokenId: string;
  }) => Promise<OwnershipBuyingRequestsOfResponse>;
  numOwnershipBuyingRequestsOf: ({ tokenId }: { tokenId: string }) => Promise<NumOwnershipBuyingRequestsOfResponse>;
  ownershipBuyingRequestOf: ({
    buyer,
    tokenId,
  }: {
    buyer: string;
    tokenId: string;
  }) => Promise<OwnershipBuyingRequestsOfResponse>;
  usageBuyingOf: ({
    buyer,
    tokenId,
    version,
  }: {
    buyer: string;
    tokenId: string;
    version?: string;
  }) => Promise<UsageBuyingOfResponse>;
}
export class DataMarketImplementQueryClient implements DataMarketImplementReadOnlyInterface {
  client: CosmWasmClient;
  contractAddress: string;

  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client;
    this.contractAddress = contractAddress;
    this.contractInfo = this.contractInfo.bind(this);
    this.offeringsOf = this.offeringsOf.bind(this);
    this.dataset = this.dataset.bind(this);
    this.datasetsByOwner = this.datasetsByOwner.bind(this);
    this.numDatasetsByOwner = this.numDatasetsByOwner.bind(this);
    this.allUsageOfferings = this.allUsageOfferings.bind(this);
    this.numAllUsageOfferings = this.numAllUsageOfferings.bind(this);
    this.allOwnershipOfferings = this.allOwnershipOfferings.bind(this);
    this.numAllOwnershipOfferings = this.numAllOwnershipOfferings.bind(this);
    this.usageBuyingsOf = this.usageBuyingsOf.bind(this);
    this.numUsageBuyingsOf = this.numUsageBuyingsOf.bind(this);
    this.ownershipBuyingRequestsOf = this.ownershipBuyingRequestsOf.bind(this);
    this.numOwnershipBuyingRequestsOf = this.numOwnershipBuyingRequestsOf.bind(this);
    this.ownershipBuyingRequestOf = this.ownershipBuyingRequestOf.bind(this);
    this.usageBuyingOf = this.usageBuyingOf.bind(this);
  }

  contractInfo = async (): Promise<ContractInfoResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      contract_info: {},
    });
  };
  offeringsOf = async ({ tokenId }: { tokenId: string }): Promise<OfferingsOfResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      offerings_of: {
        token_id: tokenId,
      },
    });
  };
  dataset = async ({ tokenId }: { tokenId: string }): Promise<DatasetReponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      dataset: {
        token_id: tokenId,
      },
    });
  };
  datasetsByOwner = async ({
    limit,
    owner,
    startAfter,
  }: {
    limit?: number;
    owner: string;
    startAfter?: string;
  }): Promise<DatasetsByOwnerResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      datasets_by_owner: {
        limit,
        owner,
        start_after: startAfter,
      },
    });
  };
  numDatasetsByOwner = async ({ owner }: { owner: string }): Promise<NumDatasetsByOwnerResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      num_datasets_by_owner: {
        owner,
      },
    });
  };
  allUsageOfferings = async ({
    limit,
    startAfter,
  }: {
    limit?: number;
    startAfter?: string;
  }): Promise<AllUsageOfferingsResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      all_usage_offerings: {
        limit,
        start_after: startAfter,
      },
    });
  };
  numAllUsageOfferings = async (): Promise<NumAllUsageOfferingsResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      num_all_usage_offerings: {},
    });
  };
  allOwnershipOfferings = async ({
    limit,
    startAfter,
  }: {
    limit?: number;
    startAfter?: string;
  }): Promise<AllOwnershipOfferingsResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      all_ownership_offerings: {
        limit,
        start_after: startAfter,
      },
    });
  };
  numAllOwnershipOfferings = async (): Promise<NumAllOwnershipOfferingsResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      num_all_ownership_offerings: {},
    });
  };
  usageBuyingsOf = async ({
    limit,
    startAfter,
    tokenId,
  }: {
    limit?: number;
    startAfter?: string;
    tokenId: string;
  }): Promise<UsageBuyingsOfResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      usage_buyings_of: {
        limit,
        start_after: startAfter,
        token_id: tokenId,
      },
    });
  };
  numUsageBuyingsOf = async ({ tokenId }: { tokenId: string }): Promise<NumUsageBuyingsOfResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      num_usage_buyings_of: {
        token_id: tokenId,
      },
    });
  };
  ownershipBuyingRequestsOf = async ({
    limit,
    startAfter,
    tokenId,
  }: {
    limit?: number;
    startAfter?: string;
    tokenId: string;
  }): Promise<OwnershipBuyingRequestsOfResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      ownership_buying_requests_of: {
        limit,
        start_after: startAfter,
        token_id: tokenId,
      },
    });
  };
  numOwnershipBuyingRequestsOf = async ({
    tokenId,
  }: {
    tokenId: string;
  }): Promise<NumOwnershipBuyingRequestsOfResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      num_ownership_buying_requests_of: {
        token_id: tokenId,
      },
    });
  };
  ownershipBuyingRequestOf = async ({
    buyer,
    tokenId,
  }: {
    buyer: string;
    tokenId: string;
  }): Promise<OwnershipBuyingRequestsOfResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      ownership_buying_request_of: {
        buyer,
        token_id: tokenId,
      },
    });
  };
  usageBuyingOf = async ({
    buyer,
    tokenId,
    version,
  }: {
    buyer: string;
    tokenId: string;
    version?: string;
  }): Promise<UsageBuyingOfResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      usage_buying_of: {
        buyer,
        token_id: tokenId,
        version,
      },
    });
  };
}
export interface DataMarketImplementInterface extends DataMarketImplementReadOnlyInterface {
  contractAddress: string;
  sender: string;
  mintDatasetNft: (
    {
      tokenId,
      tokenUri,
    }: {
      tokenId: string;
      tokenUri?: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  initDataset: (
    {
      datasource,
      isTestcase,
      tokenId,
    }: {
      datasource: Datasource;
      isTestcase: boolean;
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  initUsageOffering: (
    {
      price,
      tokenId,
      version,
    }: {
      price: Uint128;
      tokenId: string;
      version: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  updateCurrentUsageOffering: (
    {
      price,
      tokenId,
    }: {
      price: Uint128;
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  initOwnershipDataset: (
    {
      price,
      tokenId,
    }: {
      price: Uint128;
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  updateOwnershipOffering: (
    {
      price,
      tokenId,
    }: {
      price: Uint128;
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  buyUsageOffering: (
    {
      tokenId,
    }: {
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  withdrawOffering: (
    {
      ownership,
      tokenId,
      usage,
    }: {
      ownership: boolean;
      tokenId: string;
      usage: boolean;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  buyOwnershipOffering: (
    {
      tokenId,
    }: {
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  withdrawBuyRequest: (
    {
      tokenId,
    }: {
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  approveOwnershipRequest: (
    {
      toBuyer,
      tokenId,
    }: {
      toBuyer: Addr;
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  rejectOwnershipRequest: (
    {
      buyer,
      tokenId,
    }: {
      buyer: Addr;
      tokenId: string;
    },
    $fee?: number | StdFee | 'auto',
    $memo?: string,
    $funds?: Coin[],
  ) => Promise<ExecuteResult>;
  approveCw721Promotion: ($fee?: number | StdFee | 'auto', $memo?: string, $funds?: Coin[]) => Promise<ExecuteResult>;
}
export class DataMarketImplementClient extends DataMarketImplementQueryClient implements DataMarketImplementInterface {
  client: SigningCosmWasmClient;
  sender: string;
  contractAddress: string;

  constructor(client: SigningCosmWasmClient, sender: string, contractAddress: string) {
    super(client, contractAddress);
    this.client = client;
    this.sender = sender;
    this.contractAddress = contractAddress;
    this.mintDatasetNft = this.mintDatasetNft.bind(this);
    this.initDataset = this.initDataset.bind(this);
    this.initUsageOffering = this.initUsageOffering.bind(this);
    this.updateCurrentUsageOffering = this.updateCurrentUsageOffering.bind(this);
    this.initOwnershipDataset = this.initOwnershipDataset.bind(this);
    this.updateOwnershipOffering = this.updateOwnershipOffering.bind(this);
    this.buyUsageOffering = this.buyUsageOffering.bind(this);
    this.withdrawOffering = this.withdrawOffering.bind(this);
    this.buyOwnershipOffering = this.buyOwnershipOffering.bind(this);
    this.withdrawBuyRequest = this.withdrawBuyRequest.bind(this);
    this.approveOwnershipRequest = this.approveOwnershipRequest.bind(this);
    this.rejectOwnershipRequest = this.rejectOwnershipRequest.bind(this);
    this.approveCw721Promotion = this.approveCw721Promotion.bind(this);
  }

  mintDatasetNft = async (
    {
      tokenId,
      tokenUri,
    }: {
      tokenId: string;
      tokenUri?: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        mint_dataset_nft: {
          token_id: tokenId,
          token_uri: tokenUri,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  initDataset = async (
    {
      datasource,
      isTestcase,
      tokenId,
    }: {
      datasource: Datasource;
      isTestcase: boolean;
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        init_dataset: {
          datasource,
          is_testcase: isTestcase,
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  initUsageOffering = async (
    {
      price,
      tokenId,
      version,
    }: {
      price: Uint128;
      tokenId: string;
      version: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        init_usage_offering: {
          price,
          token_id: tokenId,
          version,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  updateCurrentUsageOffering = async (
    {
      price,
      tokenId,
    }: {
      price: Uint128;
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_current_usage_offering: {
          price,
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  initOwnershipDataset = async (
    {
      price,
      tokenId,
    }: {
      price: Uint128;
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        init_ownership_dataset: {
          price,
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  updateOwnershipOffering = async (
    {
      price,
      tokenId,
    }: {
      price: Uint128;
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_ownership_offering: {
          price,
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  buyUsageOffering = async (
    {
      tokenId,
    }: {
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        buy_usage_offering: {
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  withdrawOffering = async (
    {
      ownership,
      tokenId,
      usage,
    }: {
      ownership: boolean;
      tokenId: string;
      usage: boolean;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        withdraw_offering: {
          ownership,
          token_id: tokenId,
          usage,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  buyOwnershipOffering = async (
    {
      tokenId,
    }: {
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        buy_ownership_offering: {
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  withdrawBuyRequest = async (
    {
      tokenId,
    }: {
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        withdraw_buy_request: {
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  approveOwnershipRequest = async (
    {
      toBuyer,
      tokenId,
    }: {
      toBuyer: Addr;
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        approve_ownership_request: {
          to_buyer: toBuyer,
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  rejectOwnershipRequest = async (
    {
      buyer,
      tokenId,
    }: {
      buyer: Addr;
      tokenId: string;
    },
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        reject_ownership_request: {
          buyer,
          token_id: tokenId,
        },
      },
      $fee,
      $memo,
      $funds,
    );
  };
  approveCw721Promotion = async (
    $fee: number | StdFee | 'auto' = 'auto',
    $memo?: string,
    $funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        approve_cw721_promotion: {},
      },
      $fee,
      $memo,
      $funds,
    );
  };
}
