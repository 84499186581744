import React, { useState, useEffect } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import MenuItem from './MenuItem';
import { CgArrowLongLeft } from 'react-icons/cg';
import { MenuProps } from '../model';

const cx = cn.bind(styles);

export const Sidebar: React.FC<{
  menuList: MenuProps[];
  pathName: string;
  open: boolean;
  setOpen: (val: boolean) => void;
}> = ({ menuList, pathName, open, setOpen }) => {
  const [active, setActive] = useState(pathName);

  useEffect(() => {
    setActive(pathName);
  }, [pathName]);

  return (
    <div className={cx('sidebar', !open ? 'sidebar-close' : '')}>
      <div className={cx('sidebar-menu')}>
        <ul>
          {menuList.map((item) => (
            <MenuItem setActive={setActive} active={active} key={item.id} item={item} />
          ))}
        </ul>
        <div className={cx('collapse')} onClick={() => setOpen(!open)}>
          <CgArrowLongLeft />
        </div>
      </div>
    </div>
  );
};
