import styles from './index.module.scss';
import cn from 'classnames/bind';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';

const cx = cn.bind(styles);

type Options = {
  label: string;
  value: string;
  action?: () => void;
};

type DropdownProps = {
  style?: CSSProperties;
  className?: any;
  defaultValue: string;
  type?: 'select' | 'custom';
  options?: Options[];
  customTrigger?: React.ReactNode;
  customOverlay?: React.ReactNode;
};

const useOutsideHide = (ref: any, removeAction: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        removeAction();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, removeAction]);
};

const useRectPosition = (overlayRef: any, triggerRef: any) => {
  const [overlayClass, setOverlayClass] = useState('');

  useEffect(() => {
    function handleScroll() {
      if (overlayRef && triggerRef && overlayRef.current && triggerRef.current) {
        const { height } = overlayRef.current.getBoundingClientRect();
        const { top } = triggerRef.current.getBoundingClientRect();

        if (top > window.innerHeight - height - 90) {
          setOverlayClass('overlay-top');
          return;
        } else {
          setOverlayClass('overlay-bottom');
          return;
        }
      }
    }
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [overlayRef, triggerRef]);

  return overlayClass;
};

export const Dropdown = ({ className, defaultValue, options, customTrigger, customOverlay }: DropdownProps) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState('');
  const wrapperRef = useRef(null);
  const overlayRef = useRef(null);
  const triggerRef = useRef(null);

  useOutsideHide(wrapperRef, () => setActive(false));
  const overlayPosClass = useRectPosition(overlayRef, triggerRef);

  const handleActive = () => {
    active === false && setActive(true);
  };

  const handleSelect = (item: Options) => {
    setSelected(item.label);
    setActive(false);
  };

  const selectOverlay = (
    <div className={cx('dropdown-overlay')}>
      <ul>
        {options?.map((item, index) => {
          return (
            <li key={index} onClick={() => handleSelect(item)}>
              <span>{item.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div className={cx('dropdown', className)} onClick={handleActive} ref={wrapperRef}>
      <div className={cx('trigger-wrapper')} ref={triggerRef}>
        {customTrigger || (
          <div className={cx('trigger')}>
            <span>{selected || defaultValue}</span>
            <BiChevronDown size={16} />
          </div>
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <div className={cx('overlay', overlayPosClass, active && 'show')} ref={overlayRef}>
          {customOverlay || selectOverlay}
        </div>
      </div>
    </div>
  );
};
