import React, { createContext, useState, useContext } from 'react';

export interface BreadcrumbValue {
  key: string;
  title: string;
  onClick: () => void;
}
interface BreadcrumbProps {
  breadcrumbs: BreadcrumbValue[];
  setBreadcrumbs: (value: BreadcrumbValue[] | ((prevState: BreadcrumbValue[]) => BreadcrumbProps[])) => void;
}

const defaultValues: BreadcrumbProps = {
  breadcrumbs: null,
  setBreadcrumbs: null,
};

const BreadcrumbContext = createContext(defaultValues);

export const BreadcrumbProvider: React.FC<{
  children?: React.ReactElement | React.ReactNode;
}> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return <BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</BreadcrumbContext.Provider>;
};

export const useBreadcrumb = () => useContext(BreadcrumbContext);
