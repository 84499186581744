export const EndPoint = {
  //constants
  getConstants: () => '/constants',

  //user
  updateMeProfile: () => '/users/me',
  getMeAsRole: (role: string) => `/users/${role}/me`,
  getOwnerSuggestion: () => `/users/owner-suggestion`,
  getApiKey: () => '/users/me/customer-token',

  //service
  addService: () => '/services',
  getService: (id: string) => `/services/${id}`,
  getServiceList: () => '/services',
  updateService: (id: string) => `/services/${id}`,
  getEndpoint: (serviceId: string, endpointId: string) =>
    `/services/${serviceId}/endpoints/${endpointId}`,
  addEndpoint: (serviceId: string) => `/services/${serviceId}/endpoints`,
  updateEndpoint: (serviceId: string, endpointId: string) =>
    `/services/${serviceId}/endpoints/${endpointId}`,
  sellOnService: (serviceId: string) => `/services/${serviceId}/sell-on`,
  sellOffService: (serviceId: string) => `/services/${serviceId}/sell-off`,
  deleteService: (serviceId: string) => `/services/${serviceId}`,
  changeOwnership: (serviceId: string) =>
    `/services/${serviceId}/change-ownership`,
  addPackage: (serviceId: string) => `/services/${serviceId}/packages`,
  getPackages: (serviceId: string) => `/services/${serviceId}/packages`,
  updateOnSalePackages: (serviceId: string) =>
    `/services/${serviceId}/packages/update_sale_on`,
  deleteEndpoint: (serviceId: string, endpointId: string) =>
    `/services/${serviceId}/endpoints/${endpointId}`,
  getBoughtServices: () => `/services/bought`,
  getBoughtServiceDetail: (serviceId: string) =>
    `/services/bought/${serviceId}`,
  getFreePackageOnService: (serviceId: string) => `/package/${serviceId}/free`,
  executeFreeOnAnEndpoint: (serviceId: string, endpointId: string) =>
    `/services/${serviceId}/endpoints/${endpointId}/free`,
  executeOnAnEndpoint: (serviceId: string, endpointId: string) =>
    `/services/${serviceId}/endpoints/${endpointId}/paid-execute`,
  updateServiceThumbnail: (serviceId: string) =>
    `/services/${serviceId}/thumbnail`,
  testEndpoint: (serviceId: string, endpointId: string) =>
    `/services/${serviceId}/endpoints/${endpointId}/test`,

  //codegen
  codeGenForSnippet: () => `/generator/request-code-gen`,

  //upload
  imageUpload: () => `/support/image-upload`,

  //update-rating
  updateRating: (serviceId: string | string[] | undefined) =>
    `/services/${serviceId}/rate`,

  //http-statuses
  getHttpStatus: () => `/http-statuses`
};
