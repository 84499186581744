import styles from './index.module.scss';
import cn from 'classnames/bind';
import React, { CSSProperties, useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const cx = cn.bind(styles);

interface ButtonProps {
  loading?: boolean;
  children?: any;
  label?: string;
  style?: CSSProperties;
  size?: 'small' | 'default' | 'large';
  type?: 'outline' | 'default' | 'simple' | 'text';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  ref?: React.MutableRefObject<null>;
  icon?: React.ReactNode;
  typePrimary?: 'data' | 'ai';
}

export const Button = ({
  loading = false,
  children,
  label,
  style,
  type = 'outline',
  disabled,
  className,
  onClick,
  size = 'default',
  ref,
  icon,
  typePrimary = 'ai',
}: ButtonProps) => {
  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty('--primary-color', typePrimary === 'ai' ? '#dc3571' : '#f56c4e');
  }, []);
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cx(
        'button-common',
        type,
        loading ? 'loading' : '',
        disabled ? 'disable' : '',
        'size-' + size,
        className,
      )}
      style={style}
      ref={ref}
    >
      {loading && <AiOutlineLoading3Quarters className={cx('rotate')} />} {icon}
      {label || children}
    </button>
  );
};
