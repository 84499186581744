import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import cn from 'classnames/bind';

const cx = cn.bind(styles);

interface SwitchProps {
  onColor?: string;
  offColor?: string;
  className?: string;
  isOn?: boolean;
  name: string;
  reset?: boolean;
  onChange?: (e: any) => void;
}

export const Switch: React.FC<SwitchProps> = ({
  onColor = '#181818',
  offColor = '#808080',
  className,
  isOn,
  reset,
  onChange,
  name,
  ...props
}) => {
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    setIsCheck(false);
  }, [reset]);
  useEffect(() => {
    if (isOn) {
      setIsCheck(true);
      if (onChange) {
        const temp = { target: { name, checked: true } };
        onChange(temp);
      }
    }
  }, []);

  return (
    <div className={cx('switch', `${className ?? ''}`)}>
      <input
        type="checkbox"
        checked={isCheck}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          setIsCheck(e.target.checked);
        }}
        className={cx('react-switch-checkbox')}
        id={`react-switch-new`}
        name={name}
        {...props}
      />
      <label
        style={{ backgroundColor: isCheck ? onColor : offColor }}
        className={cx('react-switch-label')}
        htmlFor={`react-switch-new`}
      >
        <span className={cx('react-switch-button')} />
      </label>
    </div>
  );
};
