import './index.module.scss';
import styles from './index.module.scss';
import { UploadAvatar, UploadAvatarCover } from 'assets/icons';
import cn from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const cx = cn.bind(styles);

interface EditProfileProps {
  setStatsusAction: React.Dispatch<React.SetStateAction<'normal' | 'edit-profile' | 'transaction-history'>>;
  onSubmit?: any;
  onError?: any;
  user: any;
  typeDino: 'ai' | 'data';
}

const EditProfile: React.FC<EditProfileProps> = ({ user, setStatsusAction, onSubmit, onError, typeDino }) => {
  const [bioLength, setBioLength] = useState(0);
  const [profilePhoto, setProfilePhoto] = useState<string>('');
  const [coverPhoto, setCoverPhoto] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleRemoveImage = (type: 'coverPhoto' | 'profilePhoto') => {
    if (type === 'coverPhoto') {
      setValue('coverPhoto', '');
      setCoverPhoto('');
    } else {
      setValue('profilePhoto', '');
      setProfilePhoto('');
    }
  };

  useEffect(() => {
    if (user.coverPhoto) {
      setCoverPhoto(user.coverPhoto);
      setValue('coverPhoto', user.coverPhoto);
    } else {
      setValue('coverPhoto', '');
      setCoverPhoto('');
    }
    if (user.profilePhoto) {
      setProfilePhoto(user.profilePhoto);
      setValue('profilePhoto', user.profilePhoto);
    } else {
      setValue('profilePhoto', '');
      setProfilePhoto('');
    }
  }, [user]);

  return (
    <div className={cx('edit-profile', 'pa-38 bg-white')}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="flex-between flex-align">
          <div className="flex-col">
            <span className="fw-700 fz-28 lh-16">{user.userName}</span>
            <p className="ma-0 fw-400 fz-14 lh-15 text-info">{user.walletAddress}</p>
          </div>
          <div className="flex-align">
            <button
              onClick={() => setStatsusAction('normal')}
              className="mr-12 fw-600 fz-15 lh-15 bg-secondary text-black bd-rds-12 pt-8 pb-8 pr-20 pl-20 h-full border-none pointer"
            >
              Discard
            </button>
            <button
              className="fw-600 fz-15 lh-15 bd-rds-12 pt-8 pb-8 pr-20 pl-20 h-full border-none pointer"
              type="submit"
              style={typeDino === 'data' ? cssStylesBtnSubmit_data : cssStylesBtnSubmit_ai}
            >
              Save changes
            </button>
          </div>
        </div>
        <hr className="border-line mt-40 mb-40" />
        <div className="maw-850 w-full">
          <span className="fw-700 fz-20 lh-16">General info</span>
          <div className="flex-between flex-align mt-32">
            <label className="fw-600 fz-14 lh-15 flex-align" htmlFor="username">
              Username <span className="text-danger fw-600 fz-14 lh-15">*</span>
            </label>
            <div className="flex-col w-full flex-end">
              <input
                type="text"
                id="username"
                {...register('username', {
                  required: { value: true, message: 'This is required' },
                })}
                defaultValue={user.username}
              />
              {errors.username && errors.username.type === 'required' && (
                <span className="fw-400 fz-12 lh-14 text-danger">This is required</span>
              )}
            </div>
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15" htmlFor="displayName">
              Display Name
              <span className="text-danger fw-600 fz-14 lh-15">*</span>
              <p className="ma-0 fw-400 fz-13 lh-15 text-info">This will be displayed on your profile</p>
            </label>
            <div className="flex-col w-full flex-end">
              <input
                type="text"
                id="displayName"
                {...register('displayName', {
                  required: { value: true, message: 'This is required' },
                })}
                defaultValue={user.displayName}
              />
              {errors.displayName && errors.displayName.type === 'required' && (
                <span className="fw-400 fz-12 lh-14 text-danger">This is required</span>
              )}
            </div>
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15" htmlFor="website">
              Website
            </label>
            <input type="text" id="website" {...register('website')} defaultValue={user.website} />
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15 flex-align" htmlFor="email">
              Email <span className="text-danger fw-600 fz-14 lh-15">*</span>
            </label>
            <div className="flex-col w-full flex-end">
              <input
                type="text"
                id="email"
                {...register('email', {
                  required: { value: true, message: 'This is required' },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: '',
                  },
                })}
                defaultValue={user.email}
              />
              {errors.email && errors.email.type === 'required' && (
                <span className="fw-400 fz-12 lh-14 text-danger">This is required</span>
              )}
              {errors.email && errors.email.type === 'pattern' && (
                <span className="fw-400 fz-12 lh-14 text-danger">Please Enter A Valid Email!</span>
              )}
            </div>
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between">
            <label className="fw-600 fz-14 lh-15" htmlFor="bio">
              Bio
              <p className="ma-0 fw-400 fz-13 lh-15 text-info">A short introduction about yourself</p>
            </label>
            <div className="flex-col flex-end w-full">
              <textarea
                id="bio"
                {...register('bio', {
                  onChange: (e) => setBioLength(e.target.value.length),
                })}
                rows={5}
                maxLength={500}
                defaultValue={user.bio}
              />
              <span className="fw-400 fz-12 lh-14 text-helper">{bioLength}/500</span>
            </div>
          </div>
          <hr className="mt-40 mb-40 border-line flex-1 w-full" />
          <span className="fw-700 fz-20 lh-16">Your photo</span>
          <div className="flex-between mt-32">
            <span className="fw-600 fz-14 lh-15">Profile Photo</span>
            <div className="maw-400 w-full">
              <span className="fw-600 fz-14 lh-15">
                {profilePhoto ? (
                  <img className="w-80 h-80 bd-rds-20 object-fit-cv" src={profilePhoto} alt="uploadAvatar" />
                ) : (
                  <UploadAvatar />
                )}
              </span>
              {profilePhoto ? (
                <p className="ma-0 mt-12 flex-align">
                  <span
                    onClick={() => handleRemoveImage('profilePhoto')}
                    className="fw-600 fz-14 lh-15 text-gray pointer"
                  >
                    Remove
                  </span>
                  <label htmlFor="profilePhoto" className="ml-20 fw-600 fz-14 lh-15 text-gray block pointer">
                    Update
                  </label>
                </p>
              ) : (
                <label htmlFor="profilePhoto" className="ma-0 mt-12 fw-600 fz-14 lh-15 text-gray block pointer">
                  Upload picture
                </label>
              )}
              <input
                className="flex-none"
                id="profilePhoto"
                type="file"
                accept="image/*"
                {...register('profilePhoto', {
                  onChange: (e) => setProfilePhoto(URL.createObjectURL(e.target.files[0])),
                })}
              />
            </div>
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between">
            <span className="fw-600 fz-14 lh-15">Cover Photo</span>
            <div className="maw-400 w-full">
              <span className="fw-600 fz-14 lh-15">
                {coverPhoto ? (
                  <img className="w-310 h-111 bd-rds-16 object-fit-cv" src={coverPhoto} alt="uploadAvatarCover" />
                ) : (
                  <UploadAvatarCover />
                )}
              </span>
              {coverPhoto ? (
                <p className="ma-0 mt-12 flex-align">
                  <span
                    onClick={() => handleRemoveImage('coverPhoto')}
                    className="fw-600 fz-14 lh-15 text-gray pointer"
                  >
                    Remove
                  </span>
                  <label htmlFor="coverPhoto" className="ml-20 fw-600 fz-14 lh-15 text-gray block pointer">
                    Update
                  </label>
                </p>
              ) : (
                <label htmlFor="coverPhoto" className="ma-0 mt-12 fw-600 fz-14 lh-15 text-gray block pointer">
                  Upload picture
                </label>
              )}
              <input
                className="flex-none"
                id="coverPhoto"
                type="file"
                accept="image/*"
                {...register('coverPhoto', {
                  onChange: (e) => setCoverPhoto(URL.createObjectURL(e.target.files[0])),
                })}
              />
            </div>
          </div>
          <hr className="mt-40 mb-40 border-line" />
          <span className="fw-700 fz-20 lh-16">Social media</span>
          <p className="ma-0 fw-400 fz-14 lh-15 text-info">
            This is a highly secure platform, we don't encourage you to fill in your personal social media information!
          </p>
          <div className="flex-between flex-align mt-32">
            <label className="fw-600 fz-14 lh-15" htmlFor="Facebook">
              Facebook
            </label>
            <input type="text" id="Facebook" {...register('facebook')} defaultValue={user.facebook} />
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15" htmlFor="Twitter">
              Twitter
            </label>
            <input type="text" id="Twitter" {...register('twitter')} defaultValue={user.twitter} />
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15" htmlFor="Medium">
              Medium
            </label>
            <input type="text" id="Medium" {...register('medium')} defaultValue={user.medium} />
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15" htmlFor="Telegram">
              Telegram
            </label>
            <input type="text" id="Telegram" {...register('telegram')} defaultValue={user.telegram} />
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15" htmlFor="Linkedin">
              Linkedin
            </label>
            <input type="text" id="Linkedin" {...register('linkedin')} defaultValue={user.linkedin} />
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15" htmlFor="Instagram">
              Instagram
            </label>
            <input type="text" id="Instagram" {...register('instagram')} defaultValue={user.instagram} />
          </div>
          <hr className="mt-20 mb-20 border-line" />
          <div className="flex-between flex-align">
            <label className="fw-600 fz-14 lh-15" htmlFor="Youtube">
              Youtube
            </label>
            <input type="text" id="Youtube" {...register('youtube')} defaultValue={user.youtube} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;

const cssStylesBtnSubmit_data = { backgroundColor: '#f56c4e', color: '#fff' };
const cssStylesBtnSubmit_ai = { backgroundColor: '#dc3571', color: '#fff' };
