import React from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import cn from 'classnames/bind';
import styles from './index.module.scss';

const cx = cn.bind(styles);

type MatchVerfiedStatusProps = {
  status: string;
};

export const MatchVerfiedStatus = ({ status }: MatchVerfiedStatusProps) => {
  switch (status) {
    case 'VERIFIED':
      return (
        <div className={cx('flex-container')}>
          <IoIosCheckmarkCircle fill="#52C41A" />
          <span>Verified</span>
        </div>
      );
    case 'UNVERIFIED':
      return <span></span>;
    case 'PENDING':
      return <span>Pending</span>;
    default:
      return <span></span>;
  }
};
