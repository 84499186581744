import React from 'react';

const MultiProviders: React.FC<{
  providers: any,
  children?: any,
}> = ({ providers, children }) => {
  let content = children || null;

  /* Error/Validation */
  if (!providers) {
    throw "MultiProvider: Missing providers prop";
  }

  if (!children) {
    throw "MultiProvider: Missing children";
  }

  // Turn object into an array
  // const numberOfProviders = props.providers.size;
  const numberOfProviders = providers.length;

  if (!numberOfProviders) {
    // Providers prop is empty, r
    return content;
  }

  [...(providers ?? [])].reverse().forEach((provider) => {
    content = React.cloneElement(provider, {}, content);
  });

  return content;
}

export default MultiProviders;