import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames/bind';
import { BiChevronDown } from 'react-icons/bi';
import styles from './index.module.scss';
import { MenuProps } from '../model';
import SubMenu from './SubMenu';

const cx = cn.bind(styles);

const MenuItem: React.FC<{
  item: MenuProps;
  active: string;
  setActive: (value: string | ((prevState: string) => string)) => void;
}> = ({ item, active, setActive }) => {
  const [pathChild, setPathChild] = useState<string[]>([]);

  const handleClick = useCallback(() => {
    setActive(item.path);
    item.onClick();
  }, [item]);
  
  useEffect(() => {
    if (item.children) {
      setPathChild(item.children.map(item => item.path));
    }
  }, [item]);

  const isClassActive = useCallback(() => {
    const hasParent = pathChild.some((el) => active.includes(el));
    const isPathActive = item.pathActive?.some((el) => active === el);

    if (active === item.path || hasParent || isPathActive ) return true;
    return false;
  }, [active, pathChild]);

  return (
    <li className={cx('box-item', isClassActive() ? 'active' : '')}>
      <div 
        className={cx('item')} 
        onClick={handleClick}>
          <img src={isClassActive() ? item.iconActive : item.icon} alt="icon" /> <span>{item.title}</span>
      </div>
      {item.children && (
        <>
          <BiChevronDown />
          <ul>
            {item.children.map((child) => (
              <SubMenu 
                setActive={setActive} 
                active={active}
                key={child.id} 
                child={child} 
              />
            ))}
          </ul>
        </>
      )}
    </li>
  );
}

export default MenuItem;