import styles from './index.module.scss';
import cn from 'classnames/bind';
import LoginWidget from 'components/LoginWidget';
import React, { Suspense, useEffect, useState } from 'react';

const cx = cn.bind(styles);

export const LoginPageCommon: React.FC<{
  ssoServer: string;
  baseAuthURL: string;
  onLoginSuccess: (user: any) => void;
  textColor: string;
  mainColor: string;
  text: string;
}> = ({ ssoServer, baseAuthURL, onLoginSuccess, textColor, mainColor, text }) => {
  const [locationOrigin, setLocationOrigin] = useState('');

  useEffect(() => {
    setLocationOrigin(window.location.origin);
  }, []);

  return (
    <div className={cx('login-container')}>
      <div className={cx('cover-box')}>
        <div className={cx('row')}>
          <div className={cx('right-box')}>
            <div className={cx('card-over')}>
              <p className={cx('title')}>Use your Oraichain Wallet to use</p>
              <p className={cx('title')} style={{ color: textColor ?? '#ec5c90' }}>
                {text ?? 'DINO Hub - AI Marketplace'}
              </p>
              <Suspense>
                <LoginWidget
                  ssoServer={ssoServer}
                  reacted
                  onLoginSuccess={onLoginSuccess}
                  baseAuthURL={baseAuthURL}
                  buttonStyle={{ background: mainColor ?? '#dc3571' }}
                  mainColor="#dc3571"
                  locationOrigin={locationOrigin}
                />
              </Suspense>
            </div>
          </div>
        </div>
        <div className={cx('ocean')}>
          <div className={cx('wave')}></div>
          <div className={cx('wave')}></div>
        </div>
        <div className={cx('cover-png')}></div>
      </div>
    </div>
  );
};
