import React, { createContext, useState, useContext } from 'react';

interface ParamsProps {
  filterParams: {} | any;
  setFilterParams: React.Dispatch<React.SetStateAction<any>>;
  sortParams: {} | any;
  setSortParams: React.Dispatch<React.SetStateAction<any>>;
  searchParams: string;
  setSearchParams: React.Dispatch<React.SetStateAction<any>>;
  checkIsEnter: boolean;
  setCheckIsEnter: React.Dispatch<React.SetStateAction<any>>;
}

const defaultValues: ParamsProps = {
  filterParams: {},
  setFilterParams: () => {},
  sortParams: { created_at: 'desc' },
  setSortParams: () => {},
  searchParams: '',
  setSearchParams: () => {},
  checkIsEnter: false,
  setCheckIsEnter: () => {}
};

const ParamsContext = createContext(defaultValues);

export const ParamsProvider: React.FC<{
  children?: React.ReactElement | React.ReactNode;
}> = ({ children }) => {
  const [filterParams, setFilterParams] = useState();
  const [sortParams, setSortParams] = useState();
  const [searchParams, setSearchParams] = useState('');
  const [checkIsEnter, setCheckIsEnter] = useState(false);

  return (
    <ParamsContext.Provider
      value={{
        filterParams,
        setFilterParams,
        sortParams,
        setSortParams,
        searchParams,
        setSearchParams,
        checkIsEnter,
        setCheckIsEnter
      }}
    >
      {children}
    </ParamsContext.Provider>
  );
};

export const useParams = () => useContext(ParamsContext);
