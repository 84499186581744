import useToken from '../services/token';
import { useCallback, useMemo } from 'react';
import NodeAPI from 'services/api';

const useAuthenticate = (baseAuthURL: string) => {
  const { token, deleteToken, saveToken, deleteSsoToken, saveSsoToken } = useToken();
  const authCaller = useMemo(() => NodeAPI.factory(baseAuthURL), [baseAuthURL]);

  const isLoggedIn = !!token;

  const loginWithSSO = useCallback(
    async (ssoToken: string) => {
      if (!ssoToken) {
        return;
      }

      const result = await authCaller.execute({
        url: '/users/authenticate',
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${ssoToken}`,
        },
      });

      if (result.success) {
        saveSsoToken(ssoToken);
        saveToken(ssoToken);
      }
    },
    [saveSsoToken, saveToken],
  );

  const deleteCacheMeData = useCallback(() => {
    localStorage.removeItem('me-data');
  }, []);

  const logout = useCallback(() => {
    deleteToken();
    deleteSsoToken();
    deleteCacheMeData();
  }, [deleteToken]);

  return { loginWithSSO, logout, isLoggedIn };
};

export default useAuthenticate;
