import styles from './index.module.scss';
import cn from 'classnames/bind';
import React, { CSSProperties, useRef } from 'react';

const cx = cn.bind(styles);

export const ComingSoon: React.FC<{
  children?: any;
  style?: CSSProperties;
}> = ({ children, style }) => {
  const elRef = useRef<any>(0);
  const handleMove = (e: any) => {
    elRef.current.style.top = `${e.clientY - 20}px`;
    elRef.current.style.left = `${e.clientX}px`;
  };

  return (
    <div className={cx('coming-soon')} style={style}>
      <div
        className={cx('coming-content')}
        onMouseOver={() => (elRef.current.style.display = 'block')}
        onMouseLeave={() => (elRef.current.style.display = 'none')}
        onMouseMove={handleMove}
      >
        <div ref={elRef} className={cx('move')}>
          Coming Soon
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
export default ComingSoon;
