import styles from './index.module.scss';
import cn from 'classnames/bind';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';

const defaultCx = cn.bind(styles);

export enum SortValue {
  DESC = 'desc',
  ASC = 'asc',
}

export interface SortItem {
  name: string;
  ascLabel: string;
  descLabel: string;
  currentValue?: SortValue;
}

export interface SortProps {
  onClose: () => void;
  cx?: any;
  onChange: (sortParams: Record<string, SortValue>) => void;
  items: SortItem[];
}

export const SorterForm: React.FC<SortProps> = ({ onClose, cx = defaultCx, items, onChange }) => {
  const [sortParams, setSortParams] = useState<Record<string, SortValue>>(
    items.reduce((acc, item) => ({ ...acc, [item.name]: item.currentValue }), {}),
  );

  const handleChange = (e: any, type: string, value: SortValue) => {
    if (!e.target.checked) {
      setSortParams(omit(sortParams, [type]));
    } else setSortParams({ ...sortParams, [type]: value });
    onClose();
  };

  useEffect(() => {
    onChange?.(sortParams);
  }, [sortParams]);

  return (
    <section className={cx('sort')}>
      {items.map((item) => (
        <>
          <div>
            <input
              checked={sortParams?.[item.name] === SortValue.DESC}
              type="checkbox"
              id={`desc-${item.name}`}
              onChange={(e) => handleChange(e, item.name, SortValue.DESC)}
            />
            <label htmlFor={`desc-${item.name}`}>{item.descLabel}</label>
          </div>
          <div>
            <input
              checked={sortParams?.[item.name] === SortValue.ASC}
              type="checkbox"
              id={`asc-${item.name}`}
              onChange={(e) => handleChange(e, item.name, SortValue.ASC)}
            />
            <label htmlFor={`asc-${item.name}`}>{item.ascLabel}</label>
          </div>
        </>
      ))}
    </section>
  );
};
