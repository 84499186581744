import { isNil, omitBy } from 'lodash';

export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const removeNullOrUnd = (obj: Record<string, unknown>) => omitBy(obj, isNil);

export const centerTextEllipsis = (text: string, size?: number, key?: string) => {
  return `${text?.slice(0, size || 5)}${key || '...'}${text?.slice(-(size || 5))}`;
};

export const copyClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

// Start Create Avatar Random
const randseed = new Array(4);

function seedrand(seed: string) {
  randseed.fill(0);

  for (let i = 0; i < seed.length; i++) {
    randseed[i % 4] = (randseed[i % 4] << 5) - randseed[i % 4] + seed.charCodeAt(i);
  }
}

function rand() {
  const t = randseed[0] ^ (randseed[0] << 11);

  randseed[0] = randseed[1];
  randseed[1] = randseed[2];
  randseed[2] = randseed[3];
  randseed[3] = randseed[3] ^ (randseed[3] >> 19) ^ t ^ (t >> 8);

  return (randseed[3] >>> 0) / ((1 << 31) >>> 0);
}

function createColor() {
  //saturation is the whole color spectrum
  const h = Math.floor(rand() * 360);
  //saturation goes from 40 to 100, it avoids greyish colors
  const s = rand() * 60 + 40 + '%';
  //lightness can be anything from 0 to 100, but probabilities are a bell curve around 50%
  const l = (rand() + rand() + rand() + rand()) * 25 + '%';

  return 'hsl(' + h + ',' + s + ',' + l + ')';
}

function createImageData(size: number) {
  const width = size; // Only support square icons for now
  const height = size;

  const dataWidth = Math.ceil(width / 2);
  const mirrorWidth = width - dataWidth;

  const data = [];
  for (let y = 0; y < height; y++) {
    let row = [];
    for (let x = 0; x < dataWidth; x++) {
      // this makes foreground and background color to have a 43% (1/2.3) probability
      // spot color has 13% chance
      row[x] = Math.floor(rand() * 2.3);
    }
    const r = row.slice(0, mirrorWidth);
    r.reverse();
    row = row.concat(r);

    for (const value of row) {
      data.push(value);
    }
  }

  return data;
}

interface BuildOpts {
  seed?: string;
  size?: number;
  scale?: number;
  color?: string;
  bgcolor?: string;
  spotcolor?: string;
}

function buildOpts(opts: BuildOpts) {
  const newOpts: BuildOpts = {};

  newOpts.seed = opts.seed || Math.floor(Math.random() * Math.pow(10, 16)).toString(16);

  seedrand(newOpts.seed);

  newOpts.size = opts.size || 8;
  newOpts.scale = opts.scale || 4;
  newOpts.color = opts.color || createColor();
  newOpts.bgcolor = opts.bgcolor || createColor();
  newOpts.spotcolor = opts.spotcolor || createColor();

  return newOpts;
}
export function renderIcon(opts: BuildOpts, canvas: any) {
  opts = buildOpts(opts || {});
  const imageData = createImageData(opts.size || 8);
  const width = Math.sqrt(imageData.length);

  canvas.width = canvas.height = (opts.size || 8) * (opts.scale || 4);

  const cc = canvas.getContext('2d');
  cc.fillStyle = opts.bgcolor;
  cc.fillRect(0, 0, canvas.width, canvas.height);
  cc.fillStyle = opts.color;

  for (let i = 0; i < imageData.length; i++) {
    // if data is 0, leave the background
    if (imageData[i]) {
      const row = Math.floor(i / width);
      const col = i % width;

      // if data is 2, choose spot color, if 1 choose foreground
      cc.fillStyle = imageData[i] == 1 ? opts.color : opts.spotcolor;

      cc.fillRect(col * (opts.scale || 4), row * (opts.scale || 4), opts.scale, opts.scale);
    }
  }

  return canvas;
}
// End Create Avatar Random

export function msToTime(duration: number) {
  let seconds: number | string = Math.floor((duration / 1000) % 60);
  let minutes: number | string = Math.floor((duration / (1000 * 60)) % 60);
  const hours: number | string = Math.floor((duration / (1000 * 60 * 60)) % 24);

  // hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours + ':' + minutes + ':' + seconds;
}

export function isFirstUppercase(word: string) {
  return word[0] === word[0].toUpperCase();
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export function formatCurrency(value: string) {
  return value.replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
  });
}

export const convertSecondsToHours = (value: string) => {
  return Math.floor(Number(value) / 3600);
};

export const addFieldIndexToArr = (arr: Array<any>) => {
  arr.forEach((row, index) => {
    row.index = index + 1 < 10 ? '0' + (index + 1) : index + 1;
  });
  return arr;
};

export const getHourByPmAm = (date: Date): string => {
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  let amOrPm = 'AM';
  if (hours > 12) {
    hours -= 12;
    amOrPm = 'PM';
  }
  if (hours === 0) {
    hours = 12;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes} ${amOrPm}`;
};

export const getDate = (date: Date): string => {
  const now = new Date();
  const isToday =
    date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();

  if (isToday) {
    return 'today';
  } else {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day} ${month} ${year}`;
  }
};
