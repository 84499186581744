import React from 'react';
import cn from 'classnames/bind';
import { useMemo } from 'react';
import { IconProcess } from 'assets';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BiTransferAlt } from 'react-icons/bi';
import AsyncRequestList from './AsyncRequestList';
import styles from './index.module.scss';
import { AsyncRequestItemResponse, ASYNC_TAB, KeyTab } from './type';
import CachedLocalStorage, { LocalStorageKey } from 'utils/localStorage';
import useToken from 'services/token';
import { DropdownCustom } from 'components';
import NodeAPI from 'services/api';
const cx = cn.bind(styles);

const PAGE_SIZE = 100;

export const ProcessRunAsyncAPI: React.FC<{
  baseApiURL: string;
  onRouterPush: (url: string) => void;
  reactJson: (value: object) => React.ReactNode | React.ReactElement;
}> = ({ baseApiURL, reactJson, onRouterPush }) => {
  const [countAsync, setCountAsync] = useState<number>(0);
  const [countAsyncFree, setCountAsyncFree] = useState<number>(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isRunProcess, setIsRunProcess] = useState(false);
  const [asyncRequestList, setAsyncRequestList] = useState<AsyncRequestItemResponse[]>([]);
  const [asyncFreeRequestList, setAsyncFreeRequestList] = useState<AsyncRequestItemResponse[]>([]);
  const [tab, setTab] = useState(KeyTab.free);
  const apiCaller = useMemo(() => NodeAPI.factory(baseApiURL), [baseApiURL]);
  const interval = useRef<any>(null);
  const windowRef = useRef<any>(null);

  const { token } = useToken();
  const cacehdLocalStoraged = new CachedLocalStorage();

  const processAsync = useCallback(() => {
    const asyncRun = cacehdLocalStoraged.getItem(LocalStorageKey.asyncRun);
    if (asyncRun) {
      setIsRunProcess(true);
    } else {
      setIsRunProcess(false);
    }
  }, []);

  useEffect(() => {
    windowRef.current = window.addEventListener('storage', (e) => {
      if (e?.key === LocalStorageKey.asyncRun) {
        processAsync();
      }
    });

    processAsync();
    interval.current = setInterval(() => {
      processAsync();
    }, 1000);

    return () => {
      if (interval?.current) {
        clearInterval(interval.current);
      }
      if (windowRef?.current) {
        windowRef.current.removeEventListener('storage');
      }
    };
  }, []);

  const getListCallAsync = useCallback(
    async ({ isFree }: { isFree: boolean }) => {
      const response = await apiCaller.execute({
        url: `service-calling?page=1&size=${PAGE_SIZE}&is_free=${isFree}`,
        method: 'GET',
        contentType: 'application/json',
        isAuthen: true,
      });
      const data = response.data;
      if (response.success && response.data && Array.isArray(data.list)) {
        if (data.list) {
          const list = data.list;
          if (isFree) {
            setCountAsyncFree(list.length);
            setAsyncFreeRequestList(list);
          } else {
            setCountAsync(list.length);
            setAsyncRequestList(list);
          }
        }
      }
    },
    [token],
  );

  const hasRequestRunning = useMemo(() => {
    return (
      asyncRequestList.some((el) => el.status === 'running') ||
      asyncFreeRequestList.some((el) => el.status === 'running')
    );
  }, [asyncRequestList, asyncFreeRequestList]);

  useEffect(() => {
    setIsRunning(hasRequestRunning);
  }, [hasRequestRunning]);

  useEffect(() => {
    if (!token) return;
    getListCallAsync({ isFree: true });
    getListCallAsync({ isFree: false });
    const interval = setInterval(() => {
      if (hasRequestRunning) {
        getListCallAsync({ isFree: true });
        getListCallAsync({ isFree: false });
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [isRunProcess, hasRequestRunning, token]);

  const triggerElement = useMemo(() => {
    return (
      <div className={cx('icon', isRunning ? 'running' : '')}>
        <span>{countAsync + countAsyncFree}</span>
        <IconProcess />
        {isRunProcess && (
          <div className={cx('ic-ani')}>
            <BiTransferAlt />
          </div>
        )}
      </div>
    );
  }, [isRunning, countAsync, countAsyncFree, isRunProcess]);

  const contentDropdown = useMemo(() => {
    return (
      <div className={cx('process-wap')}>
        <div className={cx('header-tab')}>
          {ASYNC_TAB.map((item) => (
            <div
              key={item.key}
              onClick={() => setTab(item.key)}
              className={cx('tab-item', tab === item.key ? 'active' : '')}
            >
              {item.text}
            </div>
          ))}
        </div>
        <AsyncRequestList
          isFree={false}
          baseApiURL={baseApiURL}
          reactJson={reactJson}
          isShow={tab === KeyTab.paid}
          asyncRequestList={asyncRequestList}
          onRouterPush={onRouterPush}
          getListCallAsync={getListCallAsync}
        />
        <AsyncRequestList
          isFree={true}
          baseApiURL={baseApiURL}
          reactJson={reactJson}
          isShow={tab === KeyTab.free}
          asyncRequestList={asyncFreeRequestList}
          onRouterPush={onRouterPush}
          getListCallAsync={getListCallAsync}
        />
      </div>
    );
  }, [baseApiURL, reactJson, tab, asyncFreeRequestList, onRouterPush, getListCallAsync]);

  if (countAsync + countAsyncFree === 0) return null;

  return (
    <div className={cx('process-api')}>
      <DropdownCustom
        styles={{ bottom: 'calc(100% + 20px)' }}
        content={contentDropdown}
        position="top-right"
        triggerElement={triggerElement}
      />
    </div>
  );
};
