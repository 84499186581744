import React from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';

const cx = cn.bind(styles);

export const NeedInsKeplr: React.FC<{
  text?: string;
  close: () => void;
  mainColor?: string;
  icon?: any;
}> = ({ text, close, mainColor, icon }) => {
  return (
    <div className={cx('modal-keplr')}>
      <div onClick={close} className={cx('overlay')}></div>
      <div className={cx('content')}>
        <div className={cx('head')}>
          {icon && (
            <div className={cx('icon')} style={{ color: mainColor || '#4a45d4' }}>
              {icon}
            </div>
          )}
          <div className={cx('text')}>{text || 'You must install Keplr Wallet first to continue'}</div>
        </div>
        <div className={cx('footer')}>
          <button onClick={close} style={{ background: mainColor || '#4a45d4' }} className={cx('button')}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
