import { FilterElementSection, FilterForm, SortItem, SortValue, SorterForm } from './components';
import styles from './index.module.scss';
import { IconFilter, IconSort } from 'assets/icons';
import cn from 'classnames/bind';
import { DropdownCustom } from 'components/DropdownCustom';
import React, { CSSProperties, useRef } from 'react';

const cx = cn.bind(styles);

interface FilterProps {
  className: string;
  onSubmitFilter: (filters: Record<string, any>) => void;
  onChangeSorter: (sortParams: Record<string, SortValue>) => void;
  filterItems: FilterElementSection[];
  sorterItems: SortItem[];
  submitBtnStyle?: CSSProperties;
}

export const FilterBar: React.FC<FilterProps> = ({
  className,
  onChangeSorter,
  onSubmitFilter,
  filterItems,
  sorterItems,
  submitBtnStyle,
}) => {
  const refFilter = useRef<any>();
  const refSorter = useRef<any>();

  const handletoggleFilter = () => {
    refFilter.current.click();
  };
  const handletoggleSorter = () => {
    // next v
    // refSorter.current.click();
  };
  return (
    <section className={cx('filter', `${className ? className : ''}`, 'flex-align')}>
      <DropdownCustom
        triggerElement={
          <div ref={refFilter} className="flex-center pa-8 bd-rds-12 pointer">
            <IconFilter alt="icon filter" />
          </div>
        }
        content={
          <FilterForm
            submitBtnStyle={submitBtnStyle}
            onClose={handletoggleFilter}
            onSubmit={onSubmitFilter}
            filters={filterItems}
          />
        }
        position="bottom-right"
        styles={{ backgroundColor: 'transparent', right: '-5px' }}
      />
      <div className="h-20 w-1 border-btn" />
      <DropdownCustom
        triggerElement={
          <div ref={refSorter} className="flex-center pa-8 bd-rds-12 pointer">
            <IconSort alt="icon sort" />
          </div>
        }
        content={<SorterForm onClose={handletoggleSorter} items={sorterItems} onChange={onChangeSorter} />}
        position="bottom-right"
        styles={{ backgroundColor: 'transparent', right: '-5px', top: '47px' }}
      />
    </section>
  );
};

export * from './components';
