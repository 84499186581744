import styles from './index.module.scss';
import * as animationDataDefault from './lottie-loading.json';
import cn from 'classnames/bind';
import React from 'react';
import Lottie from 'react-lottie';

const cx = cn.bind(styles);

export const DinoLoading: React.FC<{
  loading?: boolean;
  animationData?: any;
  size?: number;
}> = ({ loading = true, size = 32, animationData }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData ?? animationDataDefault,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <>{loading ? <Lottie options={defaultOptions} height={size} width={size} /> : ''}</>;
};

export const DinoLoadingContainer: React.FC<{
  loading?: boolean;
  animationData?: any;
  size?: number;
  children?: any;
}> = ({ loading, children, size = 32, animationData }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData ?? animationDataDefault,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      {loading ? (
        <div className={cx('loading-container-wrapper')}>
          <Lottie options={defaultOptions} height={size} width={size} />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
