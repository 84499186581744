import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { BreadcrumbProvider } from 'providers/BreadcrumbProvider';
import MultiProviders from 'providers/MultiProviders';
import { UserProvider } from 'providers/UserProvider';
import ConstantProvider from 'providers/ConstantProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ParamsProvider } from 'providers/ParamsProvider';
import { LoadingProvider } from 'providers/LoadingProvider';

Sentry.init({
  dsn: 'https://b5782141bea04a11bd8befc1c936929f@o1325100.ingest.sentry.io/6584128',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter basename='dashboard'>
      <MultiProviders
        providers={[
          <BreadcrumbProvider key='breadcrumb' />,
          <UserProvider key='user' />,
          <ConstantProvider key='category' />,
          <ParamsProvider key='params' />,
          <LoadingProvider key='loading' />
        ]}
      >
        <App />
      </MultiProviders>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
