import config from 'config';
import { useAuthenticate } from 'dino-market-ui-seedwork';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { mainApiPort } from 'services/api/callers';
import { EndPoint } from 'services/api/EndPoint';

export interface UserValue {
  avatar?: string;
  username?: string;
  publicAddress?: string;
}

interface UserProps {
  tokenAPI: string;
  setTokenAPI: (token: string) => void;
}

const defaultValues: UserProps = {
  tokenAPI: null,
  setTokenAPI: null
};

const UserContext = createContext(defaultValues);

export const UserProvider: React.FC<{
  children?: React.ReactElement | React.ReactNode;
}> = ({ children }) => {
  const [tokenAPI, setTokenAPI] = useState(null);
  const { isLoggedIn } = useAuthenticate(config.authAPIBaseUrl);

  useEffect(() => {
    (async () => {
      const res: any = await mainApiPort.execute({
        url: EndPoint.getApiKey(),
        isAuthen: true,
        method: 'GET'
      });
      if (res.success) {
        setTokenAPI(res.data?.token);
      }
    })();
  }, [isLoggedIn]);

  return (
    <UserContext.Provider
      value={{
        tokenAPI,
        setTokenAPI
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useDashboardUser = () => useContext(UserContext);
