import styles from './index.module.scss';
import cn from 'classnames/bind';
import React, { useEffect, useState } from 'react';

const cx = cn.bind(styles);

interface RadioProps {
  className?: string;
  options: string[];
  name: string;
  reset?: boolean;
  onChange?: (e: any) => void;
}

export const Radio: React.FC<RadioProps> = ({ options, name, className, onChange, reset }) => {
  const [valueSelect, setValueSelect] = useState('');

  useEffect(() => {
    setValueSelect('');
  }, [reset]);

  return (
    <div className={cx('radio', `${className ? className : ''}`)}>
      {options.map((value) => (
        <div className="inline-block break-word h-32 mt-8" key={value}>
          <input
            checked={value === valueSelect}
            className="flex-none"
            type="radio"
            id={value}
            name={name}
            value={value}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
              setValueSelect(e.target.value);
            }}
          />
          <label
            className={cx(
              'label',
              'mr-8 bd-rds-50 border-line fw-500 fz-13 text-gray pa-8 pl-12 pr-12 pointer',
              `${value === valueSelect ? 'text-black border-solid' : ''}`,
            )}
            htmlFor={value}
          >
            {value}
          </label>
        </div>
      ))}
    </div>
  );
};
