import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import moment from 'moment';
import { BuyFailed, BuySuccess, NotiAsync } from 'assets';
import ContactModal from './ContactModal';
import useToken from 'services/token';
import { centerTextEllipsis } from 'utils';
import NodeAPI from 'services/api';
const cx = cn.bind(styles);

interface Notifi {
  content: string;
  id: string;
  is_read: boolean;
  send_at: string;
  type_id: 'BUY_PACKAGE_SUCCESS' | 'BUY_PACKAGE_TIMEOUT' | 'FINISH_ASYNC_REQUEST_INVOKING';
}

interface NotifiContent {
  package_id?: string;
  package_offering_id?: string;
  service_id?: string;
  service_name?: string;
}

const ItemNoti: React.FC<{
  noti: Notifi;
  unReadNumber: number;
  setUnReadNumber: (val: number) => void;
  onRouterPush: (val: string) => void;
  baseApiURL: string;
}> = ({ noti, onRouterPush, baseApiURL }) => {
  const apiCaller = useMemo(() => NodeAPI.factory(baseApiURL), [baseApiURL]);
  const [isRead, setIsRead] = useState(noti?.is_read || false);
  const [content, setContent] = useState<NotifiContent | null>(null);
  const [isOpenSupport, setIsOpenSupport] = useState(false);
  const { token } = useToken();

  const NOTIFICATION_TYPE = {
    BUY_PACKAGE_TIMEOUT: {
      text: `Your <b>${centerTextEllipsis(
        content?.package_offering_id || '',
      )}</b> order was failed. Please contact us for support.`,
      onClick: () => {
        setIsOpenSupport(true);
        !noti?.is_read && handleReadItem();
      },
      icon: <BuyFailed />,
    },
    BUY_PACKAGE_SUCCESS: {
      text: `You bought package from <b>${content?.service_name}</b> successfully. You can find them in Bought APIs.`,
      onClick: () => {
        onRouterPush('/apis/bought-apis/' + content?.service_id);
        !noti?.is_read && handleReadItem();
      },
      icon: <BuySuccess />,
    },
    FINISH_ASYNC_REQUEST_INVOKING: {
      text: `Your call request for API <b>${content?.service_name}</b> is completed.`,
      onClick: () => {
        !noti?.is_read && handleReadItem();
      },
      icon: <NotiAsync />,
    },
  };

  useEffect(() => {
    setContent(JSON.parse(noti?.content));
  }, [noti]);

  // useEffect(() => {
  //   if (unReadNumber === 0) {
  //     setIsRead(true);
  //   }
  // }, [unReadNumber]);

  const handleReadItem = useCallback(async () => {
    setIsRead(true);
    await apiCaller.execute({
      url: `user-notification/${noti?.id}/mark-as-read`,
      method: 'PATCH',
      contentType: 'application/json',
      isAuthen: true,
    });
    // const unReadCount = 10;
    // setUnReadNumber(unReadCount);
  }, [baseApiURL, token, noti]);

  return (
    <>
      <div onClick={() => NOTIFICATION_TYPE[noti?.type_id]?.onClick()} className={cx('noti-item')}>
        <div className={cx('item-left')}>
          <div className={cx('box-avatar')}>{NOTIFICATION_TYPE[noti?.type_id]?.icon}</div>
        </div>
        <div className={cx('item-right')}>
          <div
            style={{ fontSize: '14px' }}
            dangerouslySetInnerHTML={{ __html: NOTIFICATION_TYPE[noti?.type_id]?.text }}
          ></div>
          <div className={cx('date')}>{moment(noti?.send_at).format('DD MMM [at] h:mm a')}</div>
        </div>
        {!isRead && <span className={cx('not-read')}></span>}
      </div>
      {isOpenSupport && (
        <ContactModal
          offeringId={content?.package_offering_id}
          visible={true}
          close={() => setIsOpenSupport(false)}
          baseApiURL={baseApiURL}
        />
      )}
    </>
  );
};

export default ItemNoti;
