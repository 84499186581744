import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Noti, IcoEmptyData } from '../../../assets';
import { BsCheck2Circle } from 'react-icons/bs';
import { TiWarning } from 'react-icons/ti';
// import { useInfiniteScroll } from 'ahooks';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import ItemNoti from './ItemNoti';
import useToken from '../../../services/token';
import { useInfoUser } from 'providers/UserProvider';
import { DropdownCustom } from 'components';
import NodeAPI from 'services/api';
const cx = cn.bind(styles);

const NotificationHeader: React.FC<{
  baseApiURL: string;
  onRouterPush: (val: string) => void;
}> = ({ baseApiURL, onRouterPush }) => {
  const [unReadNumber, setUnReadNumber] = useState(0);
  const { token } = useToken();
  const listWrapRef = useRef<any>(null);
  const { notificationManager } = useInfoUser();
  // const refList = useRef<any>([]);
  const [listNoti, setListNoti] = useState<any>([]);
  const apiCaller = useMemo(() => NodeAPI.factory(baseApiURL), [baseApiURL]);

  useEffect(() => {
    notificationManager?.changeOnMessageHandlerList?.([
      async (payload: any) => {
        setUnReadNumber(unReadNumber + 1);
        // refList.current = [payload.data].concat(refList.current);
        setListNoti([payload.data].concat(listNoti));
      },
    ]);
  }, [listNoti, unReadNumber]);

  const handleReadAll = useCallback(async () => {
    await apiCaller.execute({
      url: `user-notification/mark-as-read-all`,
      method: 'POST',
      contentType: 'application/json',
      isAuthen: true,
    });
    setUnReadNumber(0);
  }, [token, baseApiURL]);

  const getLoadMoreList = async () => {
    // const params: Record<string, any> = {
    //   page_size: 10,
    // };
    // if (nextId) {
    //   params.after = nextId;
    // }
    const response = await apiCaller.execute({
      url: 'user-notification',
      method: 'GET',
      contentType: 'application/json',
      isAuthen: true,
    });
    setListNoti(response?.data?.items || []);
    // const respone = { data: [], unReadCount: 0, next_cursor: '12312312' };
    // return {
    //   list: respone.data,
    //   unReadCount: respone.unReadCount,
    //   nextId: respone.next_cursor,
    // };
  };

  // const { data }: any = useInfiniteScroll((d) => getLoadMoreList(d?.nextId), {
  //   target: listWrapRef,
  //   isNoMore: (d) => d?.nextId === undefined || d?.nextId === '',
  // });

  useEffect(() => {
    // setListNoti(refList.current.concat(data?.list));
    // setUnReadNumber(data?.unReadCount);

    if (token) {
      getLoadMoreList();
    }
  }, [token]);

  const triggerElement = useMemo(() => {
    return (
      <div
        onClick={() => {
          setUnReadNumber(0);
        }}
        className={cx('noti-box')}
      >
        <Noti />
        {!!unReadNumber && (
          <span
            className={cx('noti-has')}
            style={{ width: unReadNumber > 99 ? '20px' : '17px', borderRadius: unReadNumber > 99 ? '8px' : '50%' }}
          >
            {unReadNumber > 99 ? '99+' : unReadNumber}
          </span>
        )}
        {Notification.permission === 'denied' && (
          <div className={cx('noti-box-warning')}>
            <TiWarning />
          </div>
        )}
      </div>
    );
  }, [unReadNumber, Notification]);

  const contentDropdown = useMemo(() => {
    return (
      <div className={cx('noti-content')}>
        <div className={cx('noti-head')}>
          <span>Notifications</span>
          {!!listNoti.length && (
            <span className={cx('read-all')} onClick={handleReadAll}>
              <BsCheck2Circle /> Mark all as read
            </span>
          )}
        </div>
        {Notification.permission === 'denied' && (
          <div className={cx('noti-request-permission')}>
            <TiWarning />
            <span>Enable browser notifications to get alerts faster and easier.</span>
          </div>
        )}
        <div className={cx('noti-list')} ref={listWrapRef}>
          {listNoti.length ? (
            listNoti?.map((item: any) => (
              <ItemNoti
                key={item?.id}
                noti={item}
                setUnReadNumber={setUnReadNumber}
                unReadNumber={unReadNumber}
                onRouterPush={onRouterPush}
                baseApiURL={baseApiURL}
              />
            ))
          ) : (
            <div className={cx('noti-empty')}>
              <IcoEmptyData />
              <span>You don't have any notifications!</span>
            </div>
          )}
        </div>
      </div>
    );
  }, [listNoti, Notification]);

  return (
    <div className={cx('noti')}>
      <DropdownCustom
        styles={{ top: 'calc(100% + 18px)' }}
        position="bottom-right"
        triggerElement={triggerElement}
        content={contentDropdown}
      />
    </div>
  );
};

export default NotificationHeader;
