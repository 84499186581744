import FirebaseNotification, { FirebaseConfig } from '../app/notification';
import useAuthenticate from '../hooks/useAuthenticate';
import { useUnmount } from 'ahooks';
import INotificationManager, { NotificationSetting } from 'core/adapter/notification/INotificationManager';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import NodeAPI from 'services/api';

export const UserContext = createContext<{
  notificationManager: INotificationManager<any> | null;
  user: any;
  balanceUser: string | number;
  fetchUser: (token?: string) => void;
  fetchBalance: (walletAddress: string) => void;
}>({
  notificationManager: null,
  user: null,
  balanceUser: 0,
  fetchUser: () => null,
  fetchBalance: () => null,
});

export const UserProvider: React.FC<{
  children?: React.ReactElement | React.ReactNode;
  baseAuthURL: string;
  baseApiURL: string;
  lcdApiURL: string;
  notificationConfig?: NotificationSetting;
  typeUserRole?: string;
}> = ({ children, baseAuthURL, baseApiURL, lcdApiURL, notificationConfig, typeUserRole }) => {
  const apiCaller = useMemo(() => NodeAPI.factory(baseApiURL), [baseApiURL]);
  const apiCallerBalance = NodeAPI.factory(lcdApiURL);
  const { isLoggedIn } = useAuthenticate(baseAuthURL);
  const [user, setUser] = useState<any>(null);
  const [balanceUser, setBalanceUser] = useState(0);
  const [userNotiToken, setUserNotiToken] = useState(null);
  const firebaseNotification = useMemo(
    () => (notificationConfig ? FirebaseNotification.factory(notificationConfig as FirebaseConfig) : null),
    [],
  );
  useUnmount(() => {
    firebaseNotification && firebaseNotification.clear();
  });

  const getUser = async (token?: string) => {
    const response = await apiCaller.execute({
      url: typeUserRole ? `users/${typeUserRole}/me` : `users/me`,
      method: 'GET',
      contentType: 'application/json',
      isAuthen: true,
      token,
    });
    if (response.success) {
      setUser(response.data);
    }
  };

  const getBalance = useCallback(async (walletAddress: string) => {
    const ORAI_DENOM = 'orai';
    const response = await apiCallerBalance.execute({
      url: 'cosmos/bank/v1beta1/balances/' + walletAddress,
      method: 'GET',
      contentType: 'application/json',
    });
    const data = response.data;
    const index = data?.balances?.findIndex((item: any) => item?.denom === ORAI_DENOM);
    const hasBalanceIndex = data?.balances?.[index];
    if (hasBalanceIndex) {
      const balanceFormat = +(data?.balances?.[index]?.amount / 1000000).toFixed(3);
      setBalanceUser(balanceFormat);
    } else {
      setBalanceUser(0);
    }
  }, []);

  const getUserNotiToken = async () => {
    const response = await apiCaller.execute({
      url: 'user-notification/token',
      method: 'GET',
      contentType: 'application/json',
      isAuthen: true,
    });
    if (response.success) {
      setUserNotiToken(response.data.notification_identifier ?? null);
    }
  };

  const updateNotiToken = useCallback(async () => {
    if (!firebaseNotification) {
      return;
    }
    const firebaseToken = await firebaseNotification.initFirebaseToken();
    if (firebaseToken && userNotiToken !== firebaseToken) {
      const result = await apiCaller.execute({
        url: 'user-notification/token',
        method: 'POST',
        contentType: 'application/json',
        isAuthen: true,
        data: { token: firebaseToken },
      });
      getUserNotiToken();
      if (!result.success) {
        console.error('error in request new firebase token ', result.error);
      }
    }
  }, [userNotiToken, firebaseNotification]);

  useEffect(() => {
    if (isLoggedIn) {
      getUser();
      getUserNotiToken();
    } else if (user) {
      setUser(null);
      setUserNotiToken(null);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (user?.walletAddress) {
      getBalance(user.walletAddress);
    }
    if (user?.id) {
      updateNotiToken();
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        fetchUser: getUser,
        fetchBalance: getBalance,
        balanceUser,
        notificationManager: firebaseNotification,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useInfoUser = () => useContext(UserContext) ?? {};
