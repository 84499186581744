import React, { useCallback, useState, useRef } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import { Form, Upload, Button, Input } from 'antd';
import { BgLoginFirst } from 'assets/images';
import { AvatarPlaceholder, useInfoUser } from 'dino-market-ui-seedwork';
import { message } from 'antd';
import { EndPoint } from 'services/api/EndPoint';
import { useQueryLocation } from 'hooks/useQueryLocation';
import { useNavigate } from 'react-router-dom';
import withPrefixUrl from 'utils/withPrefixUrl';
import { isFirstUppercase } from 'utils';
import { mainApiPort } from 'services/api/callers';

const cx = cn.bind(styles);

const FirstLogin: React.FC = () => {
  const { user, fetchUser } = useInfoUser();
  const navigate = useNavigate();
  const { queries }: any = useQueryLocation();
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const avatarRef = useRef(null);
  const [form] = Form.useForm();

  const onChangeAvatar = (file: any) => {
    if (file.size / 1024 / 1024 > 20) {
      message.warning('Maximum size is 20MB');
      return;
    }
    avatarRef.current = file;
    const url = URL.createObjectURL(file);
    setAvatarPreview(url);
  };

  const handleSubmit = useCallback(async () => {
    if (form.getFieldsError().some(item => item.errors.length)) return;
    setLoading(true);
    const { username, firstName, lastName, emailAddress } =
      form.getFieldsValue();
    const formData = new FormData();
    if (avatarRef.current) formData.append('avatar', avatarRef.current);
    formData.append('username', username);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('emailAddress', emailAddress);

    const res: any = await mainApiPort.execute({
      url: EndPoint.updateMeProfile(),
      // contentType: 'multipart/form-data',
      method: 'PATCH',
      isAuthen: true,
      data: formData
    });
    if (res.success) {
      setLoading(false);
      fetchUser();
      navigate(queries.returnPath || withPrefixUrl('/apis/my-apis'));
    } else {
      setLoading(false);
      if (res.data?.detail?.validation?.email) {
        message.error(res.data?.detail?.validation?.email);
        form.setFields([
          {
            name: 'emailAddress',
            errors: [res.data?.detail?.validation?.email]
          }
        ]);
      }
    }
  }, [form, queries, navigate, fetchUser]);

  const isKYC = (user: { username: string }): boolean => {
    return Boolean(user?.username);
  };

  if (isKYC(user) || !user) return null;

  return (
    <div className={cx('first-login')}>
      <div className={cx('box')}>
        <div
          className={cx('left')}
          style={{ backgroundImage: `url(${BgLoginFirst})` }}
        >
          <div className={cx('content')}>
            <div className={cx('welcome')}>
              Welcome to <br />
              DINO Hub
            </div>
            <div className={cx('text')}>
              Annotating data. Creating data. <br />
              Using bots to crawl data. Building AI models.
            </div>
          </div>
        </div>
        <div className={cx('right')}>
          <div className={cx('content')}>
            <div className={cx('head')}>Basic information</div>
            <div className={cx('avatar')}>
              <div className={cx('box-avatar')}>
                {avatarPreview === null ? (
                  <AvatarPlaceholder
                    address={user?.walletAddress}
                    src={user?.avatar}
                  />
                ) : (
                  <img src={avatarPreview} alt='avatar' />
                )}
                {avatarPreview !== null && (
                  <button
                    onClick={() => setAvatarPreview(null)}
                    className={cx('cancel')}
                  >
                    Cancel
                  </button>
                )}
              </div>
              <div className={cx('box-text')}>
                <div className={cx('txt')}>Profile picture</div>
                <div className={cx('edit')}>
                  <Upload
                    beforeUpload={e => onChangeAvatar(e)}
                    showUploadList={false}
                  >
                    <span>Upload picture</span>
                  </Upload>
                </div>
              </div>
            </div>
            <Form
              form={form}
              onFinish={handleSubmit}
              layout='vertical'
              name='update-user'
            >
              <div style={{ display: 'flex' }}>
                <Form.Item
                  rules={[
                    { required: true, message: 'Please input first name.' },
                    ({ getFieldValue }) => ({
                      validator() {
                        if (!isFirstUppercase(getFieldValue('firstName'))) {
                          return Promise.reject(
                            new Error(
                              'First name must be started with a capital letter.'
                            )
                          );
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                  style={{ width: '50%', paddingRight: '10px' }}
                  name='firstName'
                  label='First name:'
                  initialValue={user?.firstName || ''}
                >
                  <Input placeholder='E.g. John' />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: 'Please input last name.' },
                    ({ getFieldValue }) => ({
                      validator() {
                        if (!isFirstUppercase(getFieldValue('lastName'))) {
                          return Promise.reject(
                            new Error(
                              'Last name must be started with a capital letter.'
                            )
                          );
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                  style={{ width: '50%', paddingLeft: '10px' }}
                  name='lastName'
                  label='Last name:'
                  initialValue={user?.lastName || ''}
                >
                  <Input placeholder='E.g. Smith' />
                </Form.Item>
              </div>
              <Form.Item
                rules={[{ required: true, message: 'Please input username.' }]}
                name='username'
                label='Username:'
                initialValue={user?.username || ''}
              >
                <Input placeholder='E.g. johnsmith' />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please input email.' }]}
                name='emailAddress'
                label='Email:'
                initialValue={user?.emailAddress || ''}
              >
                <Input placeholder='E.g. johnsmith@gmail.com' />
              </Form.Item>
              <div className={cx('footer')}>
                <Button
                  loading={loading}
                  className={cx('submit')}
                  htmlType='submit'
                >
                  Continue
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstLogin;
