import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { IconCheck, IconClose } from 'assets';
import cn from 'classnames/bind';
import styles from './index.module.scss';

const cx = cn.bind(styles);

type SelectItem = { value: string; label: string };

interface MultiSelectWithSearchProps {
  name: string;
  options: SelectItem[];
  searchAdvanced?: boolean;
  onChange?: (e: any) => void;
  reset?: boolean;
}

const MultiSelectWithSearch = ({
  name,
  options = [],
  searchAdvanced = false,
  onChange,
  reset,
}: MultiSelectWithSearchProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [valueSelect, setValueSelect] = useState<string[]>([]);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
      setOpen(false);
    }
  };

  const setData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setFilteredData(
        options.filter((item) => {
          if (searchAdvanced) {
            return item.value.includes(e.target.value);
          } else {
            return item.value.toLowerCase().includes(e.target.value.toLowerCase());
          }
        }),
      );
    } else {
      setFilteredData(options);
    }
  };
  const handleSelect = (item: { value: string; lable: string }) => {
    setValueSelect((pre: any) =>
      pre?.includes(item.value) ? pre.filter((e: any) => e !== item.value) : [...pre, item.value],
    );
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref]);

  useEffect(() => {
    if (onChange) {
      const temp = { target: { name, value: valueSelect } };
      onChange(temp);
    }
  }, [valueSelect, name]);

  useEffect(() => {
    setValueSelect([]);
  }, [reset]);

  return (
    <>
      <div className={cx('select', 'pos-rel')} ref={ref}>
        <BiSearch className="pos-abs w-16 h-16 text-info pos-center-y left-10" />
        <input
          type="text"
          className="border-none bg-secondary pa-8 pl-36 bd-rds-12 w-full"
          onChange={(e) => setData(e)}
          onClick={() => {
            setOpen((pre) => !pre);
            setFilteredData(filteredData || options);
          }}
          autoFocus
          placeholder="Search..."
        />
        {open && (
          <div
            className={cx(
              'content',
              'pos-abs top40 over-scroll-y bg-white z-index-3 w-full maw-320 mah-250 pa-8 bd-rds-12',
            )}
          >
            {filteredData.map((item: any) => {
              return (
                <div
                  tabIndex={0}
                  className={`flex-between pa-8 pl-12 fw-400 fz-14 lh-15 pointer ${
                    valueSelect.length && valueSelect.includes(item.value) ? 'bg-secondary' : ''
                  }`}
                  key={item.value}
                  onClick={() => handleSelect(item)}
                >
                  {item.value}
                  {valueSelect.length && valueSelect.includes(item.value) ? <IconCheck /> : ''}
                </div>
              );
            })}
          </div>
        )}
      </div>

      {valueSelect &&
        valueSelect.map((category: string) => (
          <span
            className="mt-8 mr-8 pa-6 pl-8 fw-500 fz-13 inline-block break-word bd-rds-50 bg-secondary text-gray"
            key={category}
          >
            {category}
            <IconClose
              onClick={() => handleSelect({ value: category, lable: '' })}
              className="vertical-sub ml-10 pointer"
            />
          </span>
        ))}
    </>
  );
};

export default MultiSelectWithSearch;
