import styles from './index.module.scss';
import cn from 'classnames/bind';
import { FilterBar, FilterElementSection, SortItem, SortValue } from 'components/Filter';
import React, { CSSProperties } from 'react';
import { BiSearch } from 'react-icons/bi';

const cx = cn.bind(styles);

interface SearchProps {
  style?: CSSProperties;
  placeholder?: string;
  onChange: (search: string) => void;
  value?: string;
  onEnter?: () => void;
  filterProps?: {
    onSubmitFilter: (filters: Record<string, any>) => void;
    onChangeSorter: (sortParams: Record<string, SortValue>) => void;
    filterItems: FilterElementSection[];
    sorterItems: SortItem[];
  };
  submitBtnStyle?: CSSProperties;
}

export const Search: React.FC<SearchProps> = ({
  style,
  placeholder = 'Search',
  value,
  onChange,
  onEnter,
  filterProps,
  submitBtnStyle = {},
}) => {
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onEnter?.();
    }
  };
  return (
    <div className={cx('search', 'pos-rel')} style={style}>
      <BiSearch className="pos-abs w-20 h-20 pos-center-y left-10" />
      <input
        type="text"
        className="border-none pa-16 pl-44 bd-rds-16 fw-400 fz-14"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      {filterProps && (
        <FilterBar
          className="pos-abs pos-center-y right-5 z-index-2"
          onSubmitFilter={filterProps.onSubmitFilter}
          onChangeSorter={filterProps.onChangeSorter}
          filterItems={filterProps.filterItems}
          sorterItems={filterProps.sorterItems}
          submitBtnStyle={submitBtnStyle}
        />
      )}
    </div>
  );
};
