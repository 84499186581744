import { useState, useContext, createContext, useEffect } from 'react';
import { mainApiPort } from 'services/api/callers';
import { EndPoint } from 'services/api/EndPoint';

interface CategoryProps {
  name: string,
  description: string,
}

interface ValueTypeProps {
  name: string,
  description: string,
  valueGuideText: string,
}

interface ConstantProps {
  categories: CategoryProps[],
  valueParamTypes: ValueTypeProps[],
  valueFormDataTypes: ValueTypeProps[],
}

const defaultValues: ConstantProps = {
  categories: [],
  valueParamTypes: [],
  valueFormDataTypes: [],
}

const ConstantContext = createContext(defaultValues);

const ConstantProvider: React.FC<{ 
  children?: React.ReactElement | React.ReactNode 
}> = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [valueParamTypes, setValueParamTypes] = useState([]);
  const [valueFormDataTypes, setValueFormDataTypes] = useState([]);

  const getConstants = async () => {
    const res: any = await mainApiPort.execute({ url: EndPoint.getConstants() });
    setCategories(res?.data?.categories || []);
    setValueParamTypes(res?.data?.valueParamTypes || []);
    setValueFormDataTypes(res?.data?.valueFormDataTypes || []);
  }

  useEffect(() => {
    getConstants();
  }, []);
  
  return (
    <ConstantContext.Provider value={{ categories, valueParamTypes, valueFormDataTypes }}>
      {children}
    </ConstantContext.Provider>
  );
}

export default ConstantProvider; 
export const useConstant = () => useContext(ConstantContext);