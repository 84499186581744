import styles from './index.module.scss';
import { IcoAPI, IcoDanger, IcoMarket } from 'assets/index';
import cn from 'classnames/bind';
import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const cx = cn.bind(styles);
interface HeaderProps {
  logo: React.ReactNode | React.ReactElement;
  baseAuthURL: string;
  onLoginSuccess?: (user: any) => void;
  goToMyProfile: () => void;
  goToServiceSaved: () => void;
  locationOrigin: string;
  ssoServer: string;
}

export const HeaderMobile: React.FC<HeaderProps> = ({ logo }) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div>
      <div className={cx('header')}>
        <div className={cx('box')}>
          <div className={cx('left')}>
            <div className={cx('logo')}>{logo}</div>
          </div>
          <div className={cx('right')} onClick={() => setIsShow((prev) => !prev)}>
            {isShow ? <AiOutlineClose size={24} fill="#686868" /> : <AiOutlineMenu size={24} fill="#686868" />}
          </div>
        </div>
      </div>
      <div className={cx('mobile-menu', isShow && 'menu-show')}>
        <div className={cx('menu-box')}>
          <div className={cx('tab-active')}>
            <IcoMarket />
            <span>Marketplace</span>
          </div>
          <div className={cx('tab-inactive')}>
            <IcoAPI />
            <span>APIs</span>
          </div>
          <div className={cx('danger')}>
            <IcoDanger />
            <p>
              <span>DINO Hub - AI Marketplace</span> currently does not display well and may not function properly on
              mobile devices. Please access and use the service from a computer browser. We will update mobile-friendly
              interface soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
