import React, { FC, useCallback, useMemo, useState } from 'react';
import { AsyncRequestItemProps, RequestStatus } from './type';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import { msToTime } from 'utils';
import RequestResult from './RequestResult';
import useToken from 'services/token';
import { RiCloseCircleFill } from 'react-icons/ri';
import NodeAPI from 'services/api';
const cx = cn.bind(styles);
const AsyncRequestItem: FC<AsyncRequestItemProps> = ({
  reactJson,
  serviceName,
  endpointPath,
  endpointMethod,
  status,
  msTime,
  baseApiURL,
  isFree,
  requestId,
  getListCallAsync,
  handleOnClickCallingDetail,
  statusCode,
}) => {
  const [visible, setVisible] = useState(false);
  const { token } = useToken();
  const apiCaller = useMemo(() => NodeAPI.factory(baseApiURL), [baseApiURL]);
  const handleReadRequest = useCallback(
    async (e: any) => {
      e.stopPropagation();
      await apiCaller.execute({
        url: 'service-calling/${requestId}/consider?is_free=${isFree}',
        method: 'POST',
        contentType: 'application/json',
        isAuthen: true,
      });
      getListCallAsync({ isFree });
    },
    [token, requestId, isFree],
  );

  switch (status) {
    case RequestStatus.RUNNING:
      return (
        <div className={cx('item')}>
          <div className={cx('item-title')}>{serviceName}</div>
          <div className={cx('item-method')}>
            {endpointMethod} - {endpointPath}
          </div>
          <div className={cx('item-status')}>
            <div className={cx('loading')}>
              {'Running...'.split('').map((letter: string, index: number) => (
                <div key={`${letter}-${index}`} className={cx(`l-${index + 1}`, 'letter')}>
                  {letter}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    case RequestStatus.RESOLVED:
      return (
        <>
          <div onClick={() => setVisible(true)} className={cx('item', statusCode === 200 ? 'finish' : 'failed')}>
            <div onClick={handleReadRequest} className={cx('item-close')}>
              <RiCloseCircleFill />
            </div>
            <div className={cx('item-title')}>{serviceName}</div>
            <div className={cx('item-method')}>
              {endpointMethod} - {endpointPath}
            </div>
            <div className={cx('item-status')}>
              {statusCode === 200 ? 'Completed' : 'Failed'} <span>{msToTime(msTime)}</span>
            </div>
          </div>
          {visible && (
            <RequestResult
              baseApiURL={baseApiURL}
              isFree={isFree}
              handleOnClickCallingDetail={handleOnClickCallingDetail}
              requestId={requestId}
              reactJson={reactJson}
              close={() => setVisible(false)}
            />
          )}
        </>
      );
    case RequestStatus.FAILED:
    case RequestStatus.TIMEDOUT:
      return (
        <div className={cx('item', 'failed')}>
          <div className={cx('item-close')}>
            <RiCloseCircleFill />
          </div>
          <div className={cx('item-title')}>{serviceName}</div>
          <div className={cx('item-method')}>
            {endpointMethod} - {endpointPath}
          </div>
          <div className={cx('item-status')}>
            {status === 'timedout' ? 'Timeout' : status.charAt(0).toUpperCase() + status.slice(1)}
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default AsyncRequestItem;
