export function isFirstUppercase(word: string) {
  return word[0] === word[0]?.toUpperCase();
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const convertSecondsToDays = (value: string) => {
  let seconds = parseInt(value, 10);

  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return { days, hours, minutes, seconds };
};

export const convertSecondsToHours = (value: string) => {
  return Math.floor(Number(value) / 3600);
};

export const hasWhiteSpace = (value: string) => {
  return /\s/g.test(value);
};

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isObject = input => typeof input === 'object' && input !== null
