import React, { useEffect } from 'react';
import { useAbstractModal } from './abstractModal';
import { ModalProps } from './model';

const Modal: React.FC<ModalProps> = (props) => {
  const { renderChildren, getCommonModalProps } = useAbstractModal(props);

  useEffect(() => {
    if (props?.visible) {
      document.documentElement.style.overflowY = 'hidden';
      document.documentElement.style.width = 'calc(100% - 15px)';
    } else {
      document.documentElement.style.overflowY = 'auto';
      document.documentElement.style.width = '100%';
    }
  }, [props?.visible]);

  return <div {...getCommonModalProps()}>{renderChildren()}</div>;
};

export default Modal;

export { ModalProps };
