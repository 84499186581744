const config = {
  mainAPIBaseUrl: process.env.REACT_APP_API_BASE ?? '',
  authAPIBaseUrl: process.env.REACT_APP_AUTH_BASE ?? '',
  codegenAPIBaseUrl: process.env.REACT_APP_CODE_GEN_BASE ?? '',
  addressContract: process.env.REACT_APP_AI_MARKETPLACE_CONTRACT ?? '',
  ssoServerUrl: process.env.REACT_APP_SSO_SERVER,
  optionWasm: process.env.REACT_APP_OPTION_WASM ?? '',
  lcdApiURL: process.env.REACT_APP_LCD ?? '',
  aiMarketEndpointPrefix: process.env.REACT_APP_API_AI_MARKET_PREFIX_URL ?? '',
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  }
};
export default config;
