import { Logo } from 'assets/images';
import { Link } from 'react-router-dom';

export const LOGO = (
  <Link to='/'>
    <img src={Logo} alt='logo' />
  </Link>
);

export const getPrefixUrl = () => process.env.REACT_APP_PREFIX_URL || '';
