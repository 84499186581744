import styles from './index.module.scss';
import cn from 'classnames/bind';
import { Checkbox, Radio, Switch } from 'components/Form';
import MultiSelectWithSearch from 'components/Form/MultipleSection';
import { ReactComponentLike, ReactElementLike } from 'prop-types';
import React, { CSSProperties, useRef, useState } from 'react';
import { P, match } from 'ts-pattern';

const defaultCx = cn.bind(styles);

export enum FilterElementType {
  MultiSelectWithSearch = 'MultiSelectWithSearch',
  Text = 'Text',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
  Switch = 'Switch',
  Range = 'Range',
}

export interface FilterElement {
  label?: string | ReactElementLike;
  labelCls?: string;
  wrapperCls?: string;
  type: FilterElementType | ReactComponentLike;
  name?: string;
  props?: Record<string, any>;
}

export interface FilterElementSection {
  label?: string;
  elements: FilterElement[];
}

interface FilterProps {
  cx?: any;
  onClose: () => void;
  onSubmit: (filters: Record<string, any>, e: any) => void;
  filters: FilterElementSection[];
  submitBtnStyle?: CSSProperties;
}

const renderAFilterField = (
  type: FilterElementType | ReactComponentLike,
  name: string,
  onChange: (e: any) => void,
  resetForm: boolean,
  props?: Record<string, any>,
  cx: any = defaultCx,
) => {
  return match(type)
    .with(FilterElementType.Switch, () => <Switch reset={resetForm} onChange={onChange} name={name} />)
    .with(FilterElementType.Text, () => (
      <input
        onChange={onChange}
        {...props}
        className="disable-arrows border-none bd-rds-6 pa-10 pl-12 bg-secondary text-info fw-400 fz-14"
        name={name}
      />
    ))
    .with(FilterElementType.Checkbox, () => (
      <Checkbox reset={resetForm} onChange={onChange} name={name} options={props?.options || []} />
    ))
    .with(FilterElementType.Radio, () => (
      <Radio reset={resetForm} onChange={onChange} name={name} options={props?.options || []} />
    ))
    .with(FilterElementType.MultiSelectWithSearch, () => (
      <MultiSelectWithSearch onChange={onChange} name="categories" options={props?.options} reset={resetForm} />
    ))
    .with(FilterElementType.Range, () => (
      <div className={cx('mt-8 flex-between flex-align', 'range')}>
        <input
          onChange={onChange}
          type="number"
          className="disable-arrows border-none bd-rds-6 pa-10 pl-12 bg-secondary text-info fw-400 fz-14"
          placeholder="Min"
          name={props?.minFieldName ?? 'min'}
        />
        <span className="ml-12 mr-12 fw-500 fz-12 text-info">TO</span>
        <input
          onChange={onChange}
          type="number"
          className="disable-arrows border-none bd-rds-6 pa-10 pl-12 bg-secondary text-info fw-400 fz-14"
          placeholder="Max"
          name={props?.maxFieldName ?? 'max'}
        />
      </div>
    ))
    .with(
      P.when((t) => t !== null && t !== undefined),
      (comp) => React.createElement(comp, { name, onChange }),
    )
    .otherwise(() => null);
};

export const FilterForm: React.FC<FilterProps> = ({ cx = defaultCx, submitBtnStyle, onClose, onSubmit, filters }) => {
  const form = useRef<any>(null);
  const [params, setParams] = useState<any>();
  const [resetForm, setResetForm] = useState(false);

  const onChange = (e: any) => {
    const { name, value, checked } = e.target;
    setParams({ ...params, [name]: name === 'isVerified' ? checked : value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(params, e);
    onClose?.();
  };

  const handleReset = () => {
    setResetForm((pre) => !pre);
    setParams('');
    form.current.reset();
  };

  return (
    <div className={cx('filterForm', 'mt-10 bg-white w-360 bd-rds-14')}>
      <form ref={form} onSubmit={handleSubmit} className="pa-20">
        {filters.map((filterSection: FilterElementSection) => (
          <>
            {filterSection.elements.map((filterElement) => (
              <>
                <article key={filterElement.name} className={filterElement.wrapperCls}>
                  {filterElement.label &&
                    (typeof filterElement.label === 'string' ? (
                      <span className={cx('fw-600 fz-14', filterElement.labelCls)}>{filterElement.label}</span>
                    ) : (
                      filterElement.label
                    ))}
                  {renderAFilterField(
                    filterElement.type,
                    filterElement.name || '',
                    onChange,
                    resetForm,
                    filterElement.props,
                  )}
                </article>
                <hr className="border-line-y mt-20 mb-20" />
              </>
            ))}
          </>
        ))}
        <section className="flex-between">
          <div onClick={handleReset} className="pa-8 pl-16 pr-16 text-info fw-600 fz-14 lh-14 bd-rds-12 pointer">
            Reset
          </div>
          <div className="flex-align">
            <div onClick={onClose} className="pa-8 pl-16 pr-16 border-btn fw-600 fz-13 lh-14 bd-rds-12 pointer">
              Cancel
            </div>
            <button
              type="submit"
              className="border-none pa-8 pl-16 pr-16 text-white fw-600 fz-13 lh-14 ml-8 bd-rds-12 pointer"
              style={{ backgroundColor: '#dc3571', ...submitBtnStyle }}
            >
              Show results
            </button>
          </div>
        </section>
      </form>
    </div>
  );
};

const cssStylesBtnSubmit_data = { backgroundColor: '#f56c4e' };
const cssStylesBtnSubmit_ai = { backgroundColor: '#dc3571' };
