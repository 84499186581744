import 'antd/dist/antd.min.css';
import { Cart, CartActive, Market, MarketActive } from 'assets/icons';
import config from 'config';
import {
  CommonLayout,
  LoadingPage,
  NeedInsKeplr,
  ProcessRunAsyncAPI
} from 'dino-market-ui-seedwork';
import FirstLogin from 'pages/FirstLogin';
import { useBreadcrumb } from 'providers/BreadcrumbProvider';
import { useLoading } from 'providers/LoadingProvider';
import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import ReactJson from 'react-json-view';
import { useLocation, useNavigate } from 'react-router-dom';
import DinoRoutes from 'routes';
import 'styles/global.scss';
import withPrefixUrl from 'utils/withPrefixUrl';
import { LOGO } from './constants';
require('dino-market-ui-seedwork/css/index.css');
function App(): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [locationOrigin, setLocationOrigin] = useState('');
  const [checkKeplr, setCheckKeplr] = useState('');
  const { breadcrumbs } = useBreadcrumb();
  const { pageLoading } = useLoading();

  const MENU_LIST = [
    {
      id: '2',
      title: 'Marketplace',
      path: '/',
      icon: Market,
      iconActive: MarketActive,
      onClick: () => (window.location.href = '/')
    },
    {
      id: '3',
      title: 'API management',
      path: withPrefixUrl('/apis/my-apis'),
      icon: Cart,
      iconActive: CartActive,
      onClick: () => navigate(withPrefixUrl('/apis/my-apis')),
      children: [
        {
          id: '3.1',
          title: 'My APIs',
          path: withPrefixUrl('/apis/my-apis'),
          onClick: () => navigate(withPrefixUrl('/apis/my-apis'))
        },
        {
          id: '3.2',
          title: 'Bought APIs',
          path: withPrefixUrl('/apis/bought-apis'),
          onClick: () => navigate(withPrefixUrl('/apis/bought-apis'))
        },
        {
          id: '3.3',
          title: 'Claim earnings',
          path: withPrefixUrl('/apis/claim-earnings'),
          onClick: () => navigate(withPrefixUrl('/apis/claim-earnings'))
        }
      ]
    }
  ];

  React.useEffect(() => {
    setLocationOrigin(window.location.origin);
  }, []);

  React.useEffect(() => {
    window.addEventListener('message', e => {
      if (e.data === 'must_install_keplr') {
        setCheckKeplr('You must install Keplr Wallet first to continue');
      }
    });
    return () => {
      window.removeEventListener('message', e => {
        if (e.data === 'must_install_keplr') {
          setCheckKeplr('You must install Keplr Wallet first to continue');
        }
      });
    };
  }, []);
  React.useEffect(() => {
    window.addEventListener('message', e => {
      if (e.data === 'must_login_keplr') {
        setCheckKeplr('You must login Keplr Wallet to continue');
      }
    });
    return () => {
      window.removeEventListener('message', e => {
        if (e.data === 'must_login_keplr') {
          setCheckKeplr('You must login Keplr Wallet to continue');
        }
      });
    };
  }, []);

  const reactJson = (value: object) => (
    <ReactJson
      style={{ fontSize: 14 }}
      name={null}
      enableClipboard={false}
      src={value}
      displayDataTypes={false}
    />
  );

  return (
    <CommonLayout
      baseApiURL={config.mainAPIBaseUrl}
      ssoServer={process.env.REACT_APP_SSO_SERVER}
      breadcrumbs={breadcrumbs}
      logo={LOGO}
      menuList={MENU_LIST}
      baseAuthURL={config.authAPIBaseUrl}
      goToMyProfile={() => navigate(withPrefixUrl('/user'))}
      goToServiceSaved={() => (window.location.href = '/saved-apis')}
      onRouterPush={(url: string) => navigate(withPrefixUrl(url))}
      locationOrigin={locationOrigin}
      pathName={pathname}
      typeUserRole='owner'
      notificationSetting={config.firebaseConfig}
      switchProjectList={[]}
      lcdApiURL={config.lcdApiURL}
    >
      {checkKeplr && (
        <NeedInsKeplr
          text={checkKeplr}
          mainColor='#dc3571'
          close={() => setCheckKeplr('')}
          icon={<FaInfoCircle />}
        />
      )}
      {pageLoading && <LoadingPage show />}
      <DinoRoutes />
      <ProcessRunAsyncAPI
        baseApiURL={
          process.env.REACT_APP_API_BASE +
            `/${config.aiMarketEndpointPrefix}` || ''
        }
        onRouterPush={(url: string) => navigate(withPrefixUrl(url))}
        reactJson={reactJson}
      />
      <FirstLogin />
    </CommonLayout>
  );
}

export default App;
