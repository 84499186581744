import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import Modal from 'components/Modal';
import { debounce } from 'utils';
import moment from 'moment';
import NodeAPI from 'services/api';
const cx = cn.bind(styles);

interface ResponseData {
  id: string;
  onchain_invoice: {
    paid_amount: number;
    unit_price: number;
  };
  package_id: string;
  service_id: string;
  service_name: string;
  start_at: string;
  unit_price: number;
  _link?: any;
}

const ContactItem = ({ title, content }: { title: string; content: any }) => (
  <div className={cx('contact-order-item')}>
    <span className={cx('field')}>{title}</span>
    <span className={cx('content')}>{content}</span>
  </div>
);

const orderFields = (data: ResponseData) => [
  { label: 'Order code', value: data?.id },
  { label: 'Service', value: data?.service_name },
  { label: 'Unit price', value: `${data?.unit_price} ORAI` },
  { label: 'Paid amount', value: `${data?.onchain_invoice?.paid_amount} ORAI` },
];

const ContactModal: React.FC<{
  visible: boolean;
  close: () => void;
  offeringId: string | undefined;
  baseApiURL: string;
}> = ({ visible, close, offeringId, baseApiURL }) => {
  const [body, setBody] = useState('');
  const [data, setData] = useState<ResponseData>();
  const apiCaller = useMemo(() => NodeAPI.factory(baseApiURL), [baseApiURL]);

  useEffect(() => {
    if (offeringId) {
      (async () => {
        const response: any = await apiCaller.execute({
          url: `package-offering/${offeringId}`,
          method: 'GET',
          contentType: 'application/json',
          isAuthen: true,
        });
        const data = response.data;
        setData(data);
      })();
    }
  }, [offeringId]);

  if (!data) {
    return <div />;
  }

  return (
    <Modal
      visible={visible}
      title="Contact support"
      closeIcon={false}
      headerData={{
        primary: {
          label: 'Send',
          action: () =>
            window.open(
              `mailto:dinohub@orai.io?subject=Support AI Marketplace - Offering ID: ${data?.id}&body=${body}`,
            ),
        },
      }}
      onCancel={close}
    >
      <div className={cx('contact')}>
        <div className={cx('contact-order')}>
          <div className={cx('contact-order-box')}>
            <div className={cx('contact-order-item')}>
              <span className={cx('field')}>Status</span>
              <span
                style={{
                  background: '#FFEFEF',
                  borderRadius: '6px',
                  color: '#FA3C3C',
                  padding: '4px 10px',
                  fontSize: '12px',
                }}
              >
                Failed
              </span>
            </div>
            <div className={cx('contact-order-item')}>
              <span className={cx('field')}>Time</span>
              <span className={cx('content')}>{moment(data?.start_at).format('DD MMM [at] h:mm a')}</span>
            </div>
          </div>
          <div className={cx('contact-order-box')}>
            {orderFields(data).map((field) => (
              <ContactItem key={field.label} title={field.label} content={field.value} />
            ))}
          </div>
        </div>
        <div className={cx('contact-message')}>
          <p>To: dinohub@orai.io</p>
          <textarea
            placeholder="Type your message here"
            onChange={debounce((e: any) => {
              setBody(e.target.value);
            }, 500)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
