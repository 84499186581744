import React, { CSSProperties } from 'react';
import { DinoLoading } from 'dino-market-ui-seedwork';
import * as animationData from './lottie-loading.json';

const Loading: React.FC<{
  style?: CSSProperties;
  loading?: boolean;
  size?: number;
}> = ({ style, loading, size }) => {
  return (
    <div style={style}>
      <DinoLoading
        size={size}
        loading={loading}
        animationData={animationData}
      />
    </div>
  );
};

export default Loading;
