import React from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import { AsyncRequestItemResponse } from './type';
import AsyncRequestItem from './AsyncRequestItem';
const cx = cn.bind(styles);

const AsyncRequestList: React.FC<{
  isShow: boolean;
  asyncRequestList: AsyncRequestItemResponse[];
  reactJson: (value: object) => React.ReactElement | React.ReactNode;
  baseApiURL: string;
  isFree: boolean;
  onRouterPush: (url: string) => void;
  getListCallAsync: ({ isFree }: { isFree: boolean }) => void;
}> = ({ isShow, asyncRequestList, reactJson, baseApiURL, isFree, onRouterPush, getListCallAsync }) => {
  return (
    <div className={cx('process-list', isShow ? 'show' : '')}>
      {asyncRequestList.length > 0 ? (
        <div className={cx('box')}>
          {asyncRequestList.map((asyncRequest) => (
            <AsyncRequestItem
              key={asyncRequest.id}
              serviceName={asyncRequest.service_name}
              endpointMethod={asyncRequest.endpoint_method}
              endpointPath={asyncRequest.endpoint_path}
              id={asyncRequest.id}
              status={asyncRequest.status}
              msTime={asyncRequest.elapse_time}
              reactJson={reactJson}
              isFree={isFree}
              requestId={asyncRequest.id}
              baseApiURL={baseApiURL}
              onRouterPush={onRouterPush}
              getListCallAsync={getListCallAsync}
              statusCode={asyncRequest.status_code}
            />
          ))}
        </div>
      ) : (
        <div className={cx('no-data')}>No Data</div>
      )}
    </div>
  );
};

export default AsyncRequestList;
