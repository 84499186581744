// import { IOnchainQuerier } from 'core/adapter/contracts/interface';
// import { LocalPersistance } from 'core/persistance/localPersistance';
// import Keplr from 'services/contractAdapterOraiWasm/keplr';
// import WalletStation from 'services/contractAdapterOraiWasm/keplrWalletStation';
// import Wasm from 'services/contractAdapterOraiWasm/wasm';
// import CachedLocalStorage from 'utils/localStorage';

export default class Registry {
  // static onchainInteractor(): IOnchainQuerier {
  //   return new Wasm(
  //     process.env.REACT_APP_LCD ?? 'https://lcd.orai.io',
  //     process.env.REACT_APP_NETWORK,
  //     new WalletStation(Registry.wallet()),
  //   );
  // }
  // static localStorage(): LocalPersistance {
  //   return new CachedLocalStorage();
  // }
  // static wallet(): Keplr {
  //   return new Keplr();
  // }
}
