import styles from './index.module.scss';
import cn from 'classnames/bind';
import React, { CSSProperties } from 'react';

const cx = cn.bind(styles);

export const Container: React.FC<{
  children?: string | React.ReactNode;
  style?: CSSProperties;
}> = ({ children, style }) => {
  return (
    <div style={style} className={cx('container')}>
      {children}
    </div>
  );
};
