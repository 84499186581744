import { useRef, useState, useEffect, useCallback } from 'react';

import LocalStorage from '../utils/localStorage';

const useToken = () => {
  const localStorage = new LocalStorage();
  const [token, setTokenState] = useState<any>(localStorage.getWithExpiry('token'));
  const interval = useRef<any>(null);
  const windowRef = useRef<any>(null);

  const processToken = () => {
    const currentToken = localStorage.getWithExpiry('token');

    if (token !== currentToken) {
      setTokenState(currentToken);
    }
  };

  const saveToken = useCallback(
    (newToken: any) => {
      localStorage.setWithExpiry('token', newToken, 86400 * 1000);
      setTokenState(newToken);
    },
    [token],
  );

  const deleteToken = useCallback(() => {
    localStorage.removeItem('token');
    setTokenState(null);
  }, [token]);

  const deleteSsoToken = useCallback(() => {
    localStorage.removeItem('ssoToken');
  }, []);

  const saveSsoToken = useCallback((token: any) => {
    localStorage.setItem('ssoToken', token);
  }, []);

  useEffect(() => {
    windowRef.current = window.addEventListener('storage', (e) => {
      if (e?.key === 'token') {
        processToken();
      }
    });

    processToken();
    interval.current = setInterval(() => {
      processToken();
    }, 2000);

    return () => {
      if (interval?.current) {
        clearInterval(interval.current);
      }
      if (windowRef?.current) {
        windowRef.current.removeEventListener('storage');
      }
    };
  }, []);

  return {
    token,
    saveToken,
    deleteToken,
    deleteSsoToken,
    saveSsoToken,
  };
};

export default useToken;
