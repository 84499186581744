import { LocalPersistance } from 'core/persistance/localPersistance';

export enum LocalStorageKey {
  token = 'token',
  childkey = 'childkey',
  asyncRun = 'asyncRun',
}
const fakeLocalstorage = {
  getItem: () => {
    return null;
  },

  setItem: () => null,
  removeItem: () => null,
};

class CachedLocalStorage implements LocalPersistance {
  localStorage() {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      return localStorage;
    }
    return fakeLocalstorage;
  }
  setWithExpiry(key: string, value: any, ttl: number) {
    const now = new Date();

    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    this.localStorage().setItem(key, JSON.stringify(item));
  }

  removeItem(key: string) {
    this.localStorage().removeItem(key);
  }

  setItem(key: string, value: string) {
    this.localStorage().setItem(key, value);
  }

  getItem(key: string): string | null {
    return this.localStorage().getItem(key);
  }

  getWithExpiry(key: string) {
    const itemStr = this.localStorage().getItem(key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      this.localStorage().removeItem(key);
      return null;
    }
    return item.value;
  }
}

export default CachedLocalStorage;
