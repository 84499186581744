import styles from './index.module.scss';
import { IconArrowDown, IconClose, IconPause, IconPlay, IconSuccess } from 'assets/icons';
import cn from 'classnames/bind';
import React, { memo, useEffect, useRef, useState } from 'react';

const cx = cn.bind(styles);

interface DownloadFileProps {
  fileDownLoad: {
    url: string;
    fileName: string;
  }[];
  setFileDownLoad: (
    e: {
      url: string;
      fileName: string;
    }[],
  ) => void;
}

const formatTime = (seconds: number) => {
  if (seconds < 60) {
    if (seconds < 0) {
      return '0 sec';
    }
    return `${seconds} sec`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    const remainingSeconds = seconds - minutes * 60;
    return `${minutes} min ${remainingSeconds} sec`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    const remainingMinutes = minutes - hours * 60;
    // const remainingSeconds =
    //   seconds - hours * 60 * 60 - remainingMinutes * 60;
    // return `${hours} hr ${remainingMinutes} min ${remainingSeconds} sec`;
    return `${hours} hr ${remainingMinutes} min`;
  }

  const days = Math.floor(hours / 24);
  const remainingHours = hours - days * 24;
  // const remainingMinutes = minutes - days * 24 * 60 - remainingHours * 60;
  // const remainingSeconds =
  //   seconds -
  //   days * 24 * 60 * 60 -
  //   remainingHours * 60 * 60 -
  //   remainingMinutes * 60;
  // return `${days} day ${remainingHours} hr ${remainingMinutes} min ${remainingSeconds} sec`;
  return `${days} day ${remainingHours} hr`;
};

const formatBytes = (bytes: number) => {
  if (bytes < 0) {
    return '0 B';
  }
  if (bytes < 1024) {
    return `${bytes.toFixed(1)} B`;
  }
  const kb = bytes / 1024;
  if (kb < 1024) {
    return `${kb.toFixed(1)} KB`;
  }
  const mb = kb / 1024;
  return `${mb.toFixed(1)} MB`;
};

const DownloadFile: React.FC<DownloadFileProps> = ({ fileDownLoad, setFileDownLoad }) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<'Downloading' | 'Ready' | 'Cancelled' | 'Completed' | 'Paused'>('Ready');
  const [downloadedBytes, setDownloadedBytes] = useState(0);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [isPaused, setIsPaused] = useState(false);
  const [downloadSpeed, setDownloadSpeed] = useState<number>(0);
  const [isShowDetail, setIsShowDetail] = useState(true);

  const itemsSuccess = useRef<number[]>([]);
  const current = useRef<number>(0);
  const downloadRequestRef = useRef<XMLHttpRequest | null>();
  const previousDownloadedBytesRef = useRef(downloadedBytes);
  const previousTimeRef = useRef(performance.now());
  const startTimeRef = useRef<number | null>(null);

  const isDoneProcess = itemsSuccess.current.length === fileDownLoad.length;

  const startDownload = () => {
    setStatus('Downloading');
    setIsPaused(false);
    startTimeRef.current = performance.now();
    downloadRequestRef.current = createDownloadRequest();
    downloadRequestRef.current.send();
  };

  const createDownloadRequest = () => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileDownLoad[current.current]?.url, true);
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Access-Control-Max-Age', '86400');
    xhr.setRequestHeader('cache-control', 'private');
    xhr.setRequestHeader('cache-control', 'no-store');
    // xhr.setRequestHeader('cache-control', 'no-cache');

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        setTotalFileSize(event.total);
        const percentage = Math.round((event.loaded / event.total) * 100);
        setProgress(percentage);

        setDownloadedBytes(event.loaded);

        const interval = performance.now() - previousTimeRef.current;
        if (interval >= 1000) {
          const bytesSinceLastProgress = event.loaded - previousDownloadedBytesRef.current;
          previousDownloadedBytesRef.current = event.loaded;
          const downloadSpeed = bytesSinceLastProgress / (interval / 1000);
          setDownloadSpeed(downloadSpeed);
          previousTimeRef.current = performance.now();
          const remainingFileSize = event.total - event.loaded;
          const timeRemainingSeconds = remainingFileSize / downloadSpeed;
          setTimeRemaining(Math.ceil(timeRemainingSeconds));
        }
      }
    };

    xhr.onload = (event) => {
      setProgress(100);
      if (current.current < fileDownLoad.length) {
        itemsSuccess.current.push(current.current);

        const dataBlob = new Blob([xhr.response]);
        const fileURL = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = fileDownLoad[current.current]?.fileName;
        link.click();

        current.current = current.current + 1;
        setTotalFileSize(event.total);
        setDownloadedBytes(0);
        setProgress(0);
        setDownloadSpeed(0);
        setTimeRemaining(0);
        if (current.current < fileDownLoad.length) {
          downloadRequestRef.current = createDownloadRequest();
          downloadRequestRef.current.send();
        }
      } else {
        setTimeRemaining(0);
        setDownloadedBytes(0);
        setProgress(0);
        setIsPaused(false);
        setStatus('Completed');
      }
    };

    return xhr;
  };

  const pauseDownload = () => {
    downloadRequestRef.current?.abort();
    setIsPaused(true);
    setStatus('Paused');
  };

  const cancelDownload = () => {
    downloadRequestRef.current?.abort();
    setFileDownLoad([]);
    setIsPaused(false);
    setProgress(0);
    setDownloadSpeed(0);
    setStatus('Cancelled');
  };

  useEffect(() => {
    setStatus('Ready');
    if (status === 'Ready') {
      startDownload();
    }

    return () => {
      downloadRequestRef.current?.abort();
    };
  }, []);

  useEffect(() => {
    if (status === 'Completed') {
      startDownload();
    }
    if (status === 'Downloading') {
      downloadRequestRef.current?.abort();
      startDownload();
    }
  }, [fileDownLoad]);

  const ProcessDisPlay = (file: any, notBorderLess: boolean, index: number) => (
    <div className="pl-20 pr-20">
      {!notBorderLess && <hr className="border-line ma-0" />}
      <div className="flex-align flex-between pt-16 pb-8">
        <span>{file.fileName}</span>
        {itemsSuccess.current.includes(index) ? (
          <IconSuccess />
        ) : (
          index === current.current && formatBytes(downloadedBytes) + '/ ' + formatBytes(totalFileSize)
        )}
      </div>
      {current.current === index && (
        <div className="pb-16">
          <div className="w-full h-4 bg-muted bd-rds-35">
            <div
              className={`h-4 bd-rds-35 ${isPaused ? 'bg-border' : 'bg-orange'}`}
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className={cx('download', 'w-400 bg-white bd-rds-16', `${isShowDetail ? 'pb-16' : ''}`)}>
      <div id="action-drag" className="flex-align flex-between drag pb-16 pl-20 pr-20 pt-16">
        <span id="action-drag" className="fw-600 fz-14 lh-15 drag">
          Prepare for download
        </span>
        <IconArrowDown className="pointer" onClick={() => setIsShowDetail((pre) => !pre)} />
      </div>
      <div
        className={`flex-align flex-between bg-muted pl-20 pr-20 ${!isShowDetail ? 'bd-rds-bl-16 bd-rds-br-16' : ''}`}
      >
        <span className="fw-400 fz-13 lh-15 text-gray">
          {isDoneProcess ? (
            'successfully'
          ) : (
            <span className="text-black">
              <span className="text-gray">Time remaining: </span>
              {formatTime(timeRemaining)} &bull; {formatBytes(downloadSpeed) + '/s'}
            </span>
          )}
        </span>
        <div className="flex-align pa-8 pl-2">
          {!isDoneProcess &&
            (isPaused ? (
              <IconPlay onClick={startDownload} className="pointer" />
            ) : (
              <IconPause onClick={pauseDownload} className="pointer" />
            ))}
          <IconClose onClick={cancelDownload} className="ml-10 pointer" />
        </div>
      </div>
      {isShowDetail && (
        <div className={cx('content', 'h-150 over-scroll-y')}>
          {fileDownLoad.map((item, index) => (
            <div key={index}>{ProcessDisPlay(item, index === 0, index)}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(DownloadFile);
