import classNames from 'classnames';
import { Button } from 'components/Button';
import { MdClose } from 'react-icons/md';
import React from 'react';
import styles from './index.module.scss';
import { ModalProps } from './model';

export const useAbstractModal = (props: ModalProps) => {
  const { onCancel, maskClosable = true } = props;

  const handleCancelMark = () => {
    if (onCancel && maskClosable) onCancel();
  };

  const getCommonModalProps = () => {
    // props
    const { visible, zIndex, centered } = props;

    const className = classNames(
      styles.modal,
      {
        [styles.visible]: visible,
        [styles.centered]: centered,
      },
      props.className,
    );
    const style = { zIndex };

    return {
      className,
      style,
    };
  };

  const renderChildren = () => {
    // props
    const {
      mark = true,
      top,
      width,
      border,
      borderWidth,
      borderColor,
      padding,
      borderRadius,
      closeIcon = true,
      background,
      children,
      title,
      onCancel,
      headerData,
      submitLoading,
      hasFooter = true,
    } = props;

    // style content
    const styleContent = {
      background: background ? background : '#ffffff',
      border: border ? '1px solid #e7e7e7' : 'none',
      borderColor: borderColor ? `${borderColor}` : '#e7e7e7',
      borderRadius: borderRadius ? `${borderRadius}px` : '8px',
      borderWidth: borderWidth ? `${borderWidth}px` : '1px',
      padding: padding ? `${padding}` : '0',
      top: top ? `${top}px` : '100px',
      width: width ? `${width}px` : '520px',
    };

    return (
      <>
        {mark && <div onClick={handleCancelMark} className={styles.mark} />}
        <div style={styleContent} className={styles.content}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{title}</span>
            {closeIcon && (
              <div onClick={onCancel} className={styles.close}>
                <MdClose />
              </div>
            )}
          </div>
          <div className={styles.childrenWrapper}>
            <>
              {children}
              {hasFooter && (
                <div className={styles.footer}>
                  <Button
                    type="simple"
                    onClick={onCancel}
                    style={{ ...stylesButtonModal, backgroundColor: '#F8F9FD' }}
                    className="fw-600 fz-15 lh-16 bg-secondary pa-8 pl-20 pr-20"
                  >
                    Cancel
                  </Button>
                  {headerData?.primary && (
                    <Button
                      type="default"
                      onClick={headerData.primary.action}
                      style={{ ...stylesButtonModal, marginLeft: '12px' }}
                      loading={submitLoading}
                      disabled={headerData.primary.disabled}
                      className="fw-600 fz-15 lh-16 pa-8 pl-20 pr-20 bg-pink"
                    >
                      {headerData.primary.label}
                    </Button>
                  )}
                </div>
              )}
            </>
          </div>
        </div>
      </>
    );
  };

  const stylesButtonModal = {
    padding: '8px 20px',
    border: 'none',
    borderRadius: '12px',
  };

  return { renderChildren, getCommonModalProps };
};
