import Modal from 'components/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames/bind';
import styles from './index.module.scss';
import useToken from 'services/token';
import { DinoLoading } from 'components/DinoLoading';
import { AsyncRequestDetail, HandleOnclickCallingDetail } from './type';
import { msToTime } from 'utils';
import moment from 'moment';
import { classifyMediaType, MediaType } from 'utils/classifyMediaType';
import NodeAPI from 'services/api';
const cx = cn.bind(styles);

const STATUS: any = {
  200: {
    color: '#429D15',
    bgColor: '#EEF9E8',
    text: 'Success',
  },
};

const defaultRequestResultResourceFactory = (requestId: string, isFree: boolean) =>
  `service-calling/${requestId}?is_free=${isFree}`;

const RequestResult: React.FC<{
  close: () => void;
  reactJson: (value: object) => React.ReactNode | React.ReactElement;
  baseApiURL: string;
  isFree: boolean;
  requestId: string;
  handleOnClickCallingDetail?: HandleOnclickCallingDetail;
  requestResultResourceFactory?: (requestId: string, isFree: boolean) => string;
}> = ({
  close,
  reactJson,
  baseApiURL,
  isFree,
  requestId,
  handleOnClickCallingDetail,
  requestResultResourceFactory = defaultRequestResultResourceFactory,
}) => {
  const apiCaller = useMemo(() => NodeAPI.factory(baseApiURL), [baseApiURL]);
  const [result, setResult] = useState<object | string>({});
  const [typeResult, setTypeResult] = useState(MediaType.JSON);
  const [info, setInfo] = useState<AsyncRequestDetail>();
  const [loading, setLoading] = useState(true);
  const { token } = useToken();

  const getInfoRequestAsync = useCallback(async () => {
    const response = await apiCaller.execute<AsyncRequestDetail>({
      url: requestResultResourceFactory(requestId, isFree),
      method: 'GET',
      contentType: 'application/json',
      isAuthen: true,
    });
    const data = response.data;

    setLoading(false);

    if (data) {
      setInfo(data);
      const bodyParse = JSON.parse(data?.body);
      const mediaType = classifyMediaType(bodyParse?.content_type);
      setTypeResult(mediaType);

      switch (mediaType) {
        case MediaType['TEXT']:
          setResult(bodyParse?.content);
          break;

        default:
          setResult(JSON.parse(bodyParse?.content));
          break;
      }
    }
  }, [isFree, token, requestId, baseApiURL]);

  useEffect(() => {
    if (token) {
      getInfoRequestAsync();
    }
  }, [token]);

  const renderResult: any = {
    [MediaType.JSON]: () => {
      return typeof result !== 'string' ? reactJson(result) : null;
    },
    [MediaType.TEXT]: () => {
      return typeof result === 'string' ? <div dangerouslySetInnerHTML={{ __html: result }}></div> : null;
    },
  };

  const handleGoToAPI = useCallback(() => {
    if (!info) {
      return;
    }
    const serviceId = info.service_id;
    handleCloseModal();
    handleOnClickCallingDetail?.(requestId, serviceId, isFree);
  }, [handleOnClickCallingDetail, info]);

  const handleCloseModal = useCallback(() => {
    if (document) {
      document.documentElement.style.overflowY = 'auto';
      document.documentElement.style.width = '100%';
    }
    close();
  }, [close]);

  return (
    <Modal
      headerData={{ primary: { label: 'Go to API', action: () => handleGoToAPI() } }}
      width={600}
      title="API Call Output"
      onCancel={handleCloseModal}
      visible={true}
    >
      <div className={cx('modal-result')}>
        {loading ? (
          <div className={cx('modal-loading')}>
            <DinoLoading size={26} />
          </div>
        ) : (
          <>
            <div className={cx('info-service')}>
              <div className={cx('info-item')}>
                <div className={cx('item-text')}>Status</div>
                <div
                  className={cx('item-value', 'item-status')}
                  style={{
                    background: (info?.code && STATUS?.[info?.code]?.['bgColor']) || '#ffe2e2',
                    color: (info?.code && STATUS?.[info?.code]?.['color']) || '#ff6767',
                  }}
                >
                  {(info?.code && STATUS?.[info?.code]?.['text']) || 'Failed'}
                </div>
              </div>
              {[
                { name: 'Duration', value: msToTime(info?.running_time || 0) },
                { name: 'Execution', value: moment(info?.start_at).format('lll') },
                { name: 'API name', value: info?.service_name },
              ].map((item) => (
                <div className={cx('info-item')} key={item.name}>
                  <div className={cx('item-text')}>{item.name}</div>
                  <div className={cx('item-value')}>{item.value}</div>
                </div>
              ))}
            </div>
            <div className={cx('result-service')}>
              <div className={cx('result-head')}>
                <div className={cx('result-title')}>Output</div>
              </div>
              <div className={cx('result-content')}>{renderResult[typeResult]()}</div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default RequestResult;
