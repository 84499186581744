import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames/bind';

const cx = cn.bind(styles);

interface CheckboxProps {
  className?: string;
  options: string[];
  name: string;
  reset?: boolean;
  onChange?: (e: any) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ options, name, className, onChange, reset }) => {
  const [valueSelect, setValueSelect] = useState<any>([]);

  const handleSelect = (item: any) => {
    setValueSelect((pre: any) => (pre?.includes(item) ? pre.filter((e: any) => e !== item) : [...pre, item]));
  };

  useEffect(() => {
    if (onChange) {
      const temp = { target: { name, value: valueSelect.length ? valueSelect : '' } };
      onChange(temp);
    }
  }, [valueSelect]);

  useEffect(() => {
    setValueSelect([]);
  }, [reset]);

  return (
    <div className={cx('radio', `${className ? className : ''}`)}>
      {options.map((value) => (
        <div className="inline-block break-word h-32 mt-8" key={value}>
          <input
            className="flex-none"
            type="checkbox"
            id={value}
            name={name}
            value={value}
            onChange={(e) => handleSelect(e.target.value)}
          />
          <label
            className={cx(
              'label',
              'mr-8 bd-rds-50 border-line fw-500 fz-13 text-gray pa-8 pl-12 pr-12 pointer',
              `${valueSelect.length && valueSelect.includes(value) ? 'text-black border-solid' : ''}`,
            )}
            htmlFor={value}
          >
            {value}
          </label>
        </div>
      ))}
    </div>
  );
};
